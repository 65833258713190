import { Component, OnInit, Input } from '@angular/core';
import { ImagePage } from '../../pages/modal/image/image.page';
import { ModalController } from '@ionic/angular';

export interface Audit {
  id: string;
  audit: string;
  when: string;
}


@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {
  @Input() audit: Audit;

  constructor(
    public modalCtrl: ModalController,
  ) { }

  initialize() {
    //console.log('Audit', this.audit);
  }

  ngOnInit() {
    // console.log("this.pallet", this.pallet);
    if (this.audit) {
      this.initialize();
    }

  }

}
