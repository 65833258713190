import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController, Platform } from '@ionic/angular';
import { HelpersService, CreateChildRollService } from 'src/app/services/helpers.service';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-create-child-roll',
    templateUrl: './create-child-roll.page.html',
    styleUrls: ['./create-child-roll.page.scss'],
})
export class CreateChildRollPage implements OnInit {
    @Input() node: any;
    barcode: string;
    label: string;
    po: string;
    weight: string;
    submitting = false;
    processing = false;
    progress = 50;
    constructor(
        private nav: NavController,
        private modalCtrl: ModalController,
        private platform: Platform,
        public helpers: HelpersService,
        private createChildRollService: CreateChildRollService,
    ) {}

    ngOnInit() {}

    submit(form) {
        if (!this.barcode) {
            // } && !this.weight && !this.po) {
            this.helpers.presentToast('You must enter a Barcode for this new roll');
        } else {
            this.progress = 0;
            this.submitting = true;

            console.log('About to submit');

            this.createChildRollService
                .create({
                    nid: this.node.nid,
                    barcode: this.barcode,
                    weight: this.weight,
                    po: this.po,
                    label: this.label,
                })
                .pipe(
                    tap((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                console.log('HttpEventType.UploadProgress', event);
                                this.progress = Math.round((100 * event.loaded) / event.total);
                                break;
                            case HttpEventType.DownloadProgress:
                                console.log('HttpEventType.DownloadProgress', event);
                                this.progress = -1;
                                break;
                            case HttpEventType.Response:
                                console.log('HttpEventType.Response', event);
                                this.submitting = false;
                                this.progress = 0;
                                if (event.body && event.body.success) {
                                    this.modalCtrl.dismiss({ child: event.body.child });
                                } else {
                                    this.modalCtrl.dismiss();
                                }
                                break;
                        }
                    }),
                )
                .subscribe(
                    () => {},
                    (err) => {
                        console.log('Error', err);
                        this.helpers.presentToast(err);
                        this.submitting = false;
                        this.progress = 0;
                    },
                );
        }
    }
    scan() {
        this.helpers
            .scanBarcode()
            .then((_barcode) => {
                const barcode = _barcode.substr(_barcode.lastIndexOf('/') + 1);
                if (barcode && barcode != '') {
                    this.barcode = decodeURIComponent(barcode);
                }
            })
            .catch((err) => {
                this.helpers.presentToast(err);
                console.log(err);
            });
    }
    scanPO() {
        this.helpers
            .scanBarcode()
            .then((_barcode) => {
                const barcode = _barcode.substr(_barcode.lastIndexOf('/') + 1);
                if (barcode && barcode != '') {
                    this.po = decodeURIComponent(barcode);
                }
            })
            .catch((err) => {
                this.helpers.presentToast(err);
                console.log(err);
            });
    }
    scanLabel() {
        this.helpers
            .scanBarcode()
            .then((_barcode) => {
                const barcode = _barcode.substr(_barcode.lastIndexOf('/') + 1);
                if (barcode && barcode != '') {
                    this.label = decodeURIComponent(barcode);
                }
            })
            .catch((err) => {
                this.helpers.presentToast(err);
                console.log(err);
            });
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }
}
