import { Component, OnInit } from '@angular/core';
import { CP, CONVERTING, PlantService } from '../../services/plant.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-plant-switcher',
  templateUrl: './plant-switcher.component.html',
  styleUrls: ['./plant-switcher.component.scss']
})

export class PlantSwitcherComponent implements OnInit {
  plant$: Observable<String> = this.plantService.plant;
  plant: string = CP;

  options: any = {
    CP,
    CONVERTING
  };

  constructor(private plantService: PlantService) {
    const that: any = this;
    this.plant$.subscribe({
      next(val) {
        that.plant = val;
      }
    });
  }

  segmentChanged(ev: any) {
    this.plantService.set(this.plant);
  }

  ngOnInit() {
  }
}
