<ion-header>
    <ion-toolbar color="medium">
        <ion-buttons slot="start">
            <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary" color="dark">
                <ion-icon slot="start" name="close"></ion-icon> Close
            </ion-button>
        </ion-buttons>
        <ion-title>Finish this Roll<ion-icon size="small" slot="icon-only" name="attach"></ion-icon> </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content (swipe)="helpers.swipe($event)">
    <div class="ion-padding-horizontal">
        <p>By submitting this form:</p>
        <ol>
            <li>The weight of this current Roll will zero out.</li>
            <li>This roll's PO will update to the PO you enter here.</li>
            <li>We will connect this usage to the production run's label.</li>
            <li>This roll will be connected to the Label (Production Run).</li>
        </ol>
    </div>
    <form #form="ngForm" (ngSubmit)="submit(form)">
        <ion-item>
            <ion-button slot="end" size="default" shape="round" color="dark" (click)="!this.submitting && scanPO()">
                <ion-icon name="barcode" slot="start"></ion-icon>
                <ion-label class="ion-hide-sm-down">Scan</ion-label>
            </ion-button>
            <ion-label position="fixed">PO</ion-label>
            <ion-input name="po" type="text" placeholder="..." [(ngModel)]="po"> </ion-input>
        </ion-item>
        <ion-item>
            <ion-button slot="end" size="default" shape="round" color="dark" (click)="!this.submitting && scanLabel()">
                <ion-icon name="barcode" slot="start"></ion-icon>
                <ion-label class="ion-hide-sm-down">Scan</ion-label>
            </ion-button>
            <ion-label position="fixed">Label</ion-label>
            <ion-input name="label" type="text" placeholder="..." [(ngModel)]="label"> </ion-input>
        </ion-item>
        <div class="ion-text-center">
            <app-progress-bar *ngIf="submitting" [progress]="progress"></app-progress-bar>
            <ion-button size="large" type="submit" [disabled]="submitting" expand="block"> Finish Roll </ion-button>
        </div>
    </form>
</ion-content>
