import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewService, NodeService } from 'src/app/services/ngx-drupal7-services';
import { HelpersService } from 'src/app/services/helpers.service';

import { CreateNotePage } from './../../pages/modal/create-note/create-note.page';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-pallet',
  templateUrl: './pallet.component.html',
  styleUrls: ['./pallet.component.css']
})
export class PalletComponent implements OnInit {

  loading: Boolean = true;
  @Input() pallet: any;
  @Output() modified = new EventEmitter<boolean>();

  state: any = undefined;
  label = undefined;

  constructor(
    private viewService: ViewService,
    private nodeService: NodeService,
    public helpers: HelpersService,
    private modalCtrl: ModalController
  ) {
    // TODO : CREATED AND UPDATED
    // label_and_pallets_utilities
  }


  initialize() {
    this.loading = true;
    this.helpers.getState(this.pallet).then(state => {
      this.state = state;
    });
    this.viewService.getView('label_and_pallets_utilities', { display_id: 'block_1', args: this.pallet.nid }).subscribe(data => {
      if (data && data[0]) {
        this.nodeService.getNodeById(data[0].id).subscribe(data => {
          this.label = data;
          this.loading = false;
        }, (err) => {
          this.helpers.presentToast(err);
          this.loading = false;
        });
      }
    }, (err) => {
      this.helpers.presentToast(err);
      this.loading = false;
    });
  }

  async createNote() {
    const modal = await this.modalCtrl.create({
      component: CreateNotePage,
      componentProps: { node: this.pallet }
    });
    modal.onDidDismiss().then((result: any) => {
      console.log('Create Note Dismissed', result);
      if (result.data && result.data.note) {
        this.modified.emit(true);
      }
    });
    return await modal.present();
  }

  createAudit() {
    this.helpers.createAudit(this.pallet).then((result) => {
      console.log('Create Audit Result', result);
      if (result) {
        // this.modified.emit(true);
        this.helpers.goBack();
      }
    }, err => {
      this.helpers.presentToast(err);
    });
  }
  ngOnInit() {
    // console.log("this.pallet", this.pallet);
    if (this.pallet && this.pallet.nid) {
      this.initialize();
    } else {
      this.loading = false;
    }

  }

}
