<ion-header>
  <ion-toolbar color="medium">
    <ion-buttons slot="start">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary" color="dark">
        <ion-icon slot="start" name="close"></ion-icon> Close
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <img *ngIf="value && value!=''" [src]="value" (click)="open()">
  <p class="ion-padding ion-text-center" *ngIf="!(value && value!='' )">(No Image)</p>
  <p class="ion-padding ion-text-center">{{this.caption}}</p>
</ion-content>