import {
    Component,
    Output,
    EventEmitter,
    Input,
    forwardRef,
    AfterContentChecked,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    DeliveryRoll,
    MaterialTypes,
    DeliveryRollDefaults,
    WidthOptions,
    BayLocations,
    DeliveryService,
} from 'src/app/services/delivery.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { VocabularyService } from 'src/app/services/vocabulary.service';

@Component({
    selector: 'app-delivery-roll',
    templateUrl: './delivery-roll.component.html',
    styleUrls: ['./delivery-roll.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DeliveryRollComponent),
            multi: true,
        },
    ],
})
export class DeliveryRollComponent implements ControlValueAccessor, AfterContentChecked, OnInit {
    constructor(
        public helpers: HelpersService,
        private deliveryService: DeliveryService,
        private vocabularyService: VocabularyService,
    ) {}

    @Input() material_type: MaterialTypes;
    @Input() number: any;
    @Input() expanded = true;
    @Output() cloned = new EventEmitter();
    @Output() removed = new EventEmitter();

    value: DeliveryRoll = { ...DeliveryRollDefaults };
    valid = false;
    disabled = false;

    onChange: any;
    onTouched: any;
    paperColors = [];
    coreColors = [];
    backingColors = [];
    rollColors = [];
    rollUses = [];
    widthOptions = WidthOptions;
    bayLocations = BayLocations;
    colors = [];

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    actionRemove() {
        this.helpers.presentAlertConfirm({
            header: 'Remove this Roll?',
            textConfirm: 'Yes',
            fnConfirm: () => {
                this.removed && this.removed.next();
            },
        });
    }
    actionClone() {
        this.helpers.presentAlertConfirm({
            header: 'Clone this Roll?',
            textConfirm: 'Yes',
            fnConfirm: () => {
                this.cloned && this.cloned.next();
            },
        });
    }

    public scan() {
        this.helpers
            .scanBarcode()
            .then((_barcode) => {
                const barcode = _barcode.substr(_barcode.lastIndexOf('/') + 1);
                if (barcode && barcode != '') {
                    this.value.barcode = barcode;
                }
            })
            .catch((err) => {
                this.helpers.presentToast(err);
                console.log(err);
            });
    }

    isValid() {
        this.valid = this.deliveryService.isRollValid(this.material_type, this.value);
    }

    valueChanged() {
        this.onChange(this.value);
        this.isValid();
    }
    uppercaseBarcode() {
        if (this.value.barcode) {
            this.value.barcode = this.value.barcode.toUpperCase();
        }
    }

    widthToDisplay(width: string): string {
        switch (width) {
            case '24':
                return '24 3/4';
            case '25':
                return '25 1/2';
            case '32':
                return '32 5/8';
            case '32':
                return '32 5/8';
            case '45':
                return '45 3/4';
            default:
                return width;
        }
    }

    inspect() {
        console.log('Material Type', this.material_type);
        console.log('Value', this.value);
    }

    ngAfterContentChecked() {
        this.isValid();
    }

    ngOnInit() {
        this.vocabularyService.getPaperColors().then((val) => {
            this.paperColors = val.map((item) => item.name);
        });
        this.vocabularyService.getCoreColors().then((val) => {
            this.coreColors = val.map((item) => item.name);
        });
        this.vocabularyService.getBackingColors().then((val) => {
            this.backingColors = val.map((item) => item.name);
        });
        this.vocabularyService.getRollColors().then((val) => {
            console.log('Roll Colors', val);
            this.rollColors = val.map((item) => item.name);
        });
        this.vocabularyService.getRollUses().then((val) => {
            this.rollUses = val.map((item) => item.name);
        });

        if (this.expanded && !this.value.barcode) {
            this.helpers.presentAlertConfirm({
                message: 'Scan roll now?',
                fnConfirm: () => {
                    this.scan();
                },
            });
        }
    }
}
