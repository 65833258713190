import { Injectable } from '@angular/core';
import {
    ToastController,
    AlertController,
    LoadingController,
    NavController,
    ActionSheetController,
} from '@ionic/angular';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { MainService, ViewService } from 'src/app/services/ngx-drupal7-services';
import { BackButtonService } from './back-button.service';
import { VocabularyService } from './vocabulary.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ModalController } from '@ionic/angular';
import { BarcodeScanPage } from 'src/app/pages/modal/barcode-scan/barcode-scan';

@Injectable({
    providedIn: 'root',
})
export class FieldCollectionService extends MainService {
    entityType = 'createfieldcollection';

    public create(body: any = {}, resource?: string | number): Observable<any> {
        const options = { ...this.options, observe: 'events' };
        return this.httpRequestWithConfig(
            this.httpClient.post(this.fullRequestURL(resource), body, options),
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class CreateDeliveryService extends MainService {
    entityType = 'createdelivery';
    public create(body: any = {}, resource?: string | number): any {
        const options = { ...this.options, observe: 'events' };
        return this.httpRequestWithConfig(
            this.httpClient.post(this.fullRequestURL(resource), body, options),
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class CreateChildRollService extends MainService {
    entityType = 'createchildroll';
    public create(body: any = {}, resource?: string | number): any {
        const options = { ...this.options, observe: 'events' };
        return this.httpRequestWithConfig(
            this.httpClient.post(this.fullRequestURL(resource), body, options),
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class ConsumeRollService extends MainService {
    entityType = 'consumeroll';
    public create(body: any = {}, resource?: string | number): any {
        const options = { ...this.options, observe: 'events' };
        return this.httpRequestWithConfig(
            this.httpClient.post(this.fullRequestURL(resource), body, options),
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class RawMaterialsPullService extends MainService {
    entityType = 'rawmaterialspull';
    public create(body: any = {}, resource?: string | number): any {
        const options = { ...this.options, observe: 'events' };
        return this.httpRequestWithConfig(
            this.httpClient.post(this.fullRequestURL(resource), body, options),
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class RawMaterialsPullAddService extends MainService {
    entityType = 'rawmaterialspulladd';
    public create(body: any = {}, resource?: string | number): any {
        const options = { ...this.options, observe: 'events' };
        return this.httpRequestWithConfig(
            this.httpClient.post(this.fullRequestURL(resource), body, options),
        );
    }
}

export interface ROLL_STOCK_EDIT {
    rs_width: string;
    rs_caliper: string;
    rs_diameter: string;
    weight: string;
    core_size: string;
    footage_manual_: string;
    material_type: string;
    material_type_other: string;
    warehouse_location: string;
    bay_location: string;
    rs_notes: string;
    itempo: string;
}

interface ROLL_PRIMITIVE {
    weight: string;
    rs_barcode: string;
    purchase_order: string;
}
export interface CORE_EDIT extends ROLL_PRIMITIVE {}
export interface PAPER_EDIT extends ROLL_PRIMITIVE {}
export interface BACKING_EDIT extends ROLL_PRIMITIVE {}
export interface DELIVERY_EDIT {}

export interface PALLET_EDIT {
    weight: string;
    used: boolean;
    ready_to_ship: boolean;
    number_of_sheets: string;
    location_warehouse: string;
    location_row: string;
    lineal_footage: string;
}
export interface CONFIRM {
    header?: string;
    message?: string;
    textCancel?: string;
    textConfirm?: string;
    fnCancel?: any;
    fnConfirm: any;
}
export interface ALERT {
    header: string;
    subHeader?: string;
    message?: string;
    textConfirm?: string;
    fnConfirm?: any;
}
export interface OPTIONS_BUTTON {
    text: string;
    handler: any;
}
export interface OPTIONS {
    header?: string;
    subHeader?: string;
    message?: string;
    buttons: OPTIONS_BUTTON[];
    textCancel?: string;
    fnCancel?: any;
}

@Injectable({
    providedIn: 'root',
})
export class HelpersService {
    public version: string = environment.versioning.version;

    constructor(
        public modalCtrl: ModalController,
        public toastCtrl: ToastController,
        public alertController: AlertController,
        public loadingCtrl: LoadingController,
        private authService: AuthService,
        private backButtonService: BackButtonService,
        public navCtrl: NavController,
        private fieldCollectionService: FieldCollectionService,
        public actionSheetController: ActionSheetController,
        public vocabularyService: VocabularyService,
        private viewService: ViewService,
    ) {}

    public STATES = {
        INVENTORY: {
            title: 'Inventory',
        },
        IN_PRODUCTION: {
            title: 'In Production',
        },
        READY_TO_SHIP: {
            title: 'Ready to Ship',
        },
        SHIPPED: {
            title: 'Shipped',
        },
    };

    public refresh() {
        window.location.reload();
    }

    public scanBarcode(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const modal = await this.modalCtrl.create({
                component: BarcodeScanPage,
                cssClass: 'barcodeScanner',
            });
            modal.onDidDismiss().then((result: any) => {
                if (result && result.data && result.data.barcode) {
                    resolve(result.data.barcode);
                } else {
                    resolve('');
                }
            }, reject);
            return await modal.present();
        });
    }

    public getState(node): Promise<any> {
        return new Promise((resolve, reject) => {
            const weight = this.getValue(node, 'weight');
            if (weight != undefined && weight != 0) {
                const shipped = this.getValue(node, 'used');
                if (shipped != undefined && shipped == '1') {
                    resolve(this.STATES.SHIPPED);
                } else {
                    this.viewService
                        .getView('label_and_pallets_utilities', {
                            display_id: 'block_1',
                            args: node.nid,
                        })
                        .toPromise()
                        .then((data) => {
                            console.log('get node res', node);
                            console.log('block result', data);

                            if (data && data[0] && data[0].inventory && data[0].inventory == '1') {
                                const ready_to_ship = this.getValue(node, 'ready_to_ship');
                                if (ready_to_ship != undefined && ready_to_ship == '1') {
                                    resolve(this.STATES.READY_TO_SHIP);
                                } else {
                                    resolve(this.STATES.INVENTORY);
                                }
                            } else {
                                resolve(this.STATES.READY_TO_SHIP);
                            }
                        }, reject);
                }
            } else {
                resolve(this.STATES.IN_PRODUCTION);
            }
        });
    }

    public prepValue(value) {
        if (value) {
            return { und: [{ value }] };
        } else {
            return { und: [{ value: '' }] };
        }
    }
    public prepTaxonomyValue(value) {
        if (value) {
            return { und: [value] };
        } else {
            return { und: [] };
        }
    }

    public prepBoolean(value) {
        if (value) {
            return { und: [{ value: 1 }] };
        } else {
            return { und: null };
        }
    }

    public async presentToast(msg) {
        if (!msg || msg.length == 0 || msg == '' || !(typeof msg === 'string')) {
            return;
        }
        const toast = await this.toastCtrl.create({
            message: msg,
            duration: 1750,
            position: 'top',
            color: 'primary',
        });
        toast.present();
    }

    async presentAlertConfirm(confirm: CONFIRM) {
        const alert = await this.alertController.create({
            header: confirm.header ? confirm.header : 'Please Confirm',
            message: confirm.message ? confirm.message : '',
            buttons: [
                {
                    text: confirm.textCancel ? confirm.textCancel : 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        confirm.fnCancel && confirm.fnCancel();
                    },
                },
                {
                    text: confirm.textConfirm ? confirm.textConfirm : 'Okay',
                    handler: () => {
                        confirm.fnConfirm && confirm.fnConfirm();
                    },
                },
            ],
        });
        await alert.present();
    }

    async presentAlert(alert: ALERT) {
        const prompt = await this.alertController.create({
            header: alert.header ? alert.header : 'Alert',
            subHeader: alert.subHeader ? alert.subHeader : null,
            message: alert.message ? alert.message : '',
            buttons: [
                {
                    text: alert.textConfirm ? alert.textConfirm : 'Okay',
                    handler: () => {
                        alert.fnConfirm && alert.fnConfirm();
                    },
                },
            ],
        });

        await prompt.present();
    }

    async presentOptions(options: OPTIONS) {
        let controller: any = this.actionSheetController;
        /*if (options.type && options.type == "alert") {
      controller = this.alertController;
    }*/
        const prompt = await controller.create({
            header: options.header ? options.header : 'Choose',
            subHeader: options.subHeader ? options.subHeader : null,
            message: options.message ? options.message : '',
            buttons: [
                ...options.buttons,
                {
                    text: options.textCancel ? options.textCancel : 'Cancel',
                    handler: () => {
                        options.fnCancel && options.fnCancel();
                    },
                },
            ],
        });

        await prompt.present();
    }

    public async logout() {
        const loader = await this.loadingCtrl.create();
        loader.present();
        this.authService.logout().then(
            () => {
                loader.dismiss();
            },
            (err) => {
                console.log('Error logging out', err);
                loader.dismiss();
            },
        );
    }

    public createAudit(node): Promise<Boolean> {
        return new Promise((resolve, reject) => {
            this.vocabularyService.getAuditOptions().then(
                async (options) => {
                    console.log('Audit Options!!!', options);
                    const selected = (button) => {
                        const value = {
                            type: 'audit',
                            nid: node.nid,
                            fields: {
                                field_when: this.prepValue(
                                    moment().add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                                ),
                                field_audit_value: { und: [{ tid: button.tid }] },
                            },
                        };
                        console.log('Audit Value', value);
                        this.fieldCollectionService
                            .create(value)
                            .toPromise()
                            .then(
                                () => {
                                    resolve(true);
                                },
                                (err) => {
                                    reject(err);
                                },
                            );
                    };
                    const buttons = options.map((button) => {
                        console.log(button);
                        return {
                            text: button.name,
                            icon: button.description,
                            handler: () => {
                                selected(button);
                            },
                        };
                    });
                    buttons.push({
                        text: 'Cancel',
                        icon: 'close',
                        role: 'cancel',
                        handler: () => {
                            resolve(false);
                        },
                    });
                    const actionSheet = await this.actionSheetController.create({
                        header: 'Audit this Item',
                        buttons,
                    });
                    await actionSheet.present();
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    public getValue(node, name) {
        const field = node['field_' + name];
        if (field) {
            if (field.und) {
                if (field.und[0]) {
                    return field.und[0].value;
                }
            }
        }
        return undefined;
    }
    public getTaxonomyValue(node, name) {
        const field = node['field_' + name];
        if (field) {
            if (field.und) {
                if (field.und[0]) {
                    return field.und[0].tid;
                }
            }
        }
        return undefined;
    }

    swipe(event) {
        if (event.direction == 4) {
            //
            this.swipeRight();
        }
        return event;
    }
    swipeRight() {
        this.goBack();
    }
    public goBack() {
        const previous = this.backButtonService.getPreviousUrl();
        if (previous && previous != '' && previous != '/') {
            this.navCtrl.navigateBack(previous);
        }
    }

    navigating = false;
    public go(link, forward = true) {
        console.log(`helpers.go('${link}');`);
        if (!this.navigating) {
            if (forward) {
                this.navCtrl
                    .navigateForward(link)
                    .then(
                        () => {},
                        (err) => {
                            this.presentToast(err);
                        },
                    )
                    .finally(() => {
                        this.navigating = false;
                    });
            } else {
                this.navCtrl
                    .navigateBack(link)
                    .then(
                        () => {},
                        (err) => {
                            this.presentToast(err);
                        },
                    )
                    .finally(() => {
                        this.navigating = false;
                    });
            }
        } else {
            console.log(`Already navigating skipping go for ${link}`);
        }
    }

    gotoPallet(id) {
        this.go(`/main/dashboard/pallet/${this.stripHtml(id)}/workflow`);
    }

    public stripHtml(_value) {
        if (_value && _value.length > 0) {
            let ret = _value.replace(/<(?:.|\n)*?>/gm, '');
            ret = ret.replace('&amp;', '&');
            return ret;
        } else {
            return '';
        }
    }

    public array(n: number): any[] {
        return Array(n);
    }
    public arrayRandom(min, max): any[] {
        return this.array(Math.floor(Math.random() * max + min + 1));
    }
    public randomWidth(minimum = 0) {
        return Math.floor(Math.random() * (100 - minimum) + minimum + 1) + '%';
    }
}
