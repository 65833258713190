<ion-toolbar color="medium" class="search-bar" class="ion-no-padding">
  <ion-searchbar [ngClass]="{'loading':this.loading}" [(ngModel)]="searchKey" placeholder="RAW or Barcode " (keyup.enter)="search()">
  </ion-searchbar>
  <ion-buttons slot="start" class="ion-padding-start">
    <ion-spinner *ngIf="this.loading"></ion-spinner>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button size="large" shape="round" color="dark" (click)="!this.loading && scan()">
      <ion-icon name="barcode" slot="start"></ion-icon>
      <ion-label class="ion-hide-sm-down">Scan</ion-label>
    </ion-button>
    <ion-button size="large" shape="round" color="dark" (click)="!this.loading && search()" class="ion-padding-end">
      <ion-icon name="search" slot="start"></ion-icon>
      <ion-label class="ion-hide-sm-down">Search</ion-label>
    </ion-button>
  </ion-buttons>
</ion-toolbar>