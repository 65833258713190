// const host: string = "nal.local";
const host = 'shop.nalcompany.com';
//const host = 'files.centogram.com/s/proxy/?url=https://shop.nalcompany.com'
const protocol = 'https://';
const base_url: string = protocol + host;
const base_install_url: string = base_url + '/install/';
const app_name = 'NAL';
const app_file = 'nal';
import packageInfo from '../../package.json';

export const defaults = {
    production: false,
    secretKey: '$4Iqiot@JD@GeM7!$pYdp3XEODRIK$3*aK8&',
    apiHost: host,
    apiURL: base_url + '/api/',
    freshness: 900 as number, // SECONDS
    freshnessVocab: 900 as number, // SECONDS
    requestTimeout: 25000,
    versioning: {
        version: packageInfo.version,
    },
};
