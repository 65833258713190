<div *ngIf="inline && initialized && ((final!=null && final.length!=0 && final!='' && final.changingThisBreaksApplicationSecurity!='') || showIfEmpty )"
    class="ion-text-wrap inline" lines="inset">
    <strong class="ion-text-wrap" slot="start">{{label}}</strong>
    <ion-text [ngClass]="{'link':externalLink}" (click)="clicked()" tappable class="ion-text-wrap">
        {{prefix}}<span [innerHTML]="final" appRouteTransformer></span>{{suffix}} <ion-icon *ngIf="externalLink"
            size="small" color="primary" name="link"></ion-icon>
    </ion-text>
</div>


<ion-item
    *ngIf="!inline && initialized && ((final!=null && final.length!=0 && final!='' && final.changingThisBreaksApplicationSecurity!='') || showIfEmpty )"
    class="ion-text-wrap" lines="inset">
    <ion-label class="ion-text-wrap" slot="start">{{label}}</ion-label>
    <ion-text [ngClass]="{'link':externalLink}" (click)="clicked()" tappable class="ion-text-wrap">
        {{prefix}}<span [innerHTML]="final" appRouteTransformer></span>{{suffix}} <ion-icon *ngIf="externalLink"
            size="small" color="primary" name="link"></ion-icon>
    </ion-text>
</ion-item>
<div *ngIf="notes">
    <ion-item lines="none">
        <ion-label position="fixed" class="ion-text-wrap"><small>{{label}}</small></ion-label>
    </ion-item>
    <app-note *ngFor="let note of notes" [note]="note"></app-note>
</div>
<div *ngIf="audits">
    <ion-item lines="none">
        <ion-label position="fixed" class="ion-text-wrap"><small>{{label}}</small></ion-label>
    </ion-item>
    <app-audit *ngFor="let audit of audits" [audit]="audit"></app-audit>
</div>

<div *ngIf="raw_materials" class="raw_materials">
    <ion-item lines="none">
        <div class="ion-text-wrap label" slot="start">{{label}}</div>
    </ion-item>
    <div *ngFor="let item of raw_materials">
        <ion-card (click)="clickedRaw(item)" class="ion-button">
            <ion-card-content>
                {{item.pulled == 'No' ? 'Need to
                      Pull' : 'Already Pulled' }}
                <app-block-roll-stock [node]="item.roll">
                </app-block-roll-stock>
            </ion-card-content>
        </ion-card>
    </div>
</div>