import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController, Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image',
  templateUrl: './image.page.html',
  styleUrls: ['./image.page.scss'],
})
export class ImagePage implements OnInit {
  @Input() value: any;
  @Input() caption: any;

  public image: any;

  constructor(
    private nav: NavController,
    private modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    private platform: Platform
  ) { }

  ngOnInit() {
    this.image = this.sanitizer.bypassSecurityTrustStyle(this.value);
  }

  open() {
    /*if (this.platform.is('ios') || this.platform.is('android')) {
      this.photoViewer.show(this.value, this.caption);
    }*/
    alert("open image not yet implemented in pwa")
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
