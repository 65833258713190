<ion-list class="ion-text-center" *ngIf="!loading && !node">
    <p>Unable to load details</p>
</ion-list>
<ion-list *ngIf="node">
    <ion-item-divider>
        <ion-label>Core Details</ion-label>
    </ion-item-divider>
    <app-value-print label="ID" [value]="node.nid"></app-value-print>
    <app-value-print label="Color" [value]="node.field_core_color2"></app-value-print>
    <app-value-print label="Initial Weight" [value]="node.field_initial_weight"></app-value-print>
    <app-value-print label="Weight" [value]="node.field_weight"></app-value-print>
    <app-value-print label="Bay Location" [value]="node.field_bay_location"></app-value-print>
    <app-value-print label="Comments" [value]="node.field_comments"></app-value-print>
    <app-value-print label="LF Sht" [value]="node.field_lf_sht"></app-value-print>
    <app-value-print label="Manufacturer" [value]="node.field_manufacturer"></app-value-print>
    <app-value-print label="Pending" [value]="node.field_pending" type="boolean"></app-value-print>
    <app-value-print label="PO" [value]="node.field_purchase_order"></app-value-print>
    <app-value-print label="Production Run (Label)" [value]="node.field_label"></app-value-print>
    <app-value-print label="Roll TXT" [value]="node.field_roll_txt"></app-value-print>
    <app-value-print label="Barcode" [value]="node.field_rs_barcode"></app-value-print>
    <app-value-print label="Size" [value]="node.field_size"></app-value-print>
    <app-value-print label="Warehouse Location" [value]="node.field_warehouse_location"></app-value-print>
    <app-value-print label="Parent Core Roll" [value]="node.field_master_roll" type="barcodes"></app-value-print>
    <app-value-print label="Created Core Roll(s)" [value]="node.field_child_rolls" type="barcodes"></app-value-print>
    <div class="ion-text-center">
        <ion-button expand="block" (click)="createNote()" size="large">Attach Note <ion-icon name="attach" slot="end"> </ion-icon> </ion-button>
        <ion-button expand="block" (click)="createAudit()" size="large">Audit <ion-icon name="clipboard" slot="end"> </ion-icon> </ion-button>
        <ion-button expand="block" (click)="createChildRoll()" size="large" class="ion-text-wrap">
            <div>
                <div>Use Some</div>
                <small>(Create Child Roll)</small>
            </div>
        </ion-button>
        <ion-button expand="block" (click)="consumeRoll()" size="large" class="ion-text-wrap">
            <div>
                <div>Use All</div>
                <small>(Finish Roll)</small>
            </div>
        </ion-button>
    </div>
    <app-value-print label="Notes" [value]="node.field_media_notes" type="notes"></app-value-print>
    <app-value-print label="Audits" [value]="node.field_audit" type="audits"></app-value-print>
</ion-list>

<p class="ion-text-center" *ngIf="loading">
    <ion-spinner></ion-spinner>
</p>
