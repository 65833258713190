import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewService } from 'src/app/services/ngx-drupal7-services';
import { HelpersService } from 'src/app/services/helpers.service';

import { CreateNotePage } from './../../pages/modal/create-note/create-note.page';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent implements OnInit {

  loading: Boolean = true;
  @Input() node: any;
  @Output() modified = new EventEmitter<boolean>();

  pending;

  constructor(
    private viewService: ViewService,
    public helpers: HelpersService,
    private modalCtrl: ModalController
  ) {
    // TODO : CREATED AND UPDATED
    // label_and_pallets_utilities
  }


  initialize() {
    this.loading = true;
    this.viewService.getView('deliveries', { display_id: 'page_4', args: this.node.nid }).subscribe(data => {
      if (data && data[0]) {
        this.pending = true;
      } else {
        this.pending = false;
      }
      console.log('Pending', this.pending);
      this.loading = false;
    }, (err) => {
      this.helpers.presentToast(err);
      this.loading = false;
    });
    //console.log('Node', this.node);
  }

  async createNote() {
    const modal = await this.modalCtrl.create({
      component: CreateNotePage,
      componentProps: { node: this.node }
    });
    modal.onDidDismiss().then((result: any) => {
      //console.log('Create Note Dismissed', result);
      if (result.data && result.data.note) {
        this.modified.emit(true);
      }
    });
    return await modal.present();
  }

  ngOnInit() {
    if (this.node && this.node.nid) {
      this.initialize();
    } else {
      this.loading = false;
    }

  }

}
