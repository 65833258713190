<ion-header>
  <ion-toolbar color="medium">
    <ion-buttons slot="start">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary" color="dark">
        <ion-icon slot="start" name="close"></ion-icon> Close
      </ion-button>
    </ion-buttons>
    <ion-title>Attach Note <ion-icon size="small" slot="icon-only" name="attach"></ion-icon>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content (swipe)="helpers.swipe($event)">
  <form #form="ngForm" (ngSubmit)="submit(form)">
    <ion-item>
      <ion-label position="stacked">Image</ion-label>
      <input type="file" id="fileLoader" #fileLoader name="files" title="Load File" hidden />
      <ion-button *ngIf="image === null && !processing" [disabled]="submitting" size="large" (click)="presentUploader(fileLoader)" expand="block">
        <ion-icon name="camera"></ion-icon> Select Image
      </ion-button>
      <p class="ion-text-center" *ngIf="processing">
        <ion-spinner name="dots"></ion-spinner><br />
        Processing Image
      </p>
      <ion-button size="large" *ngIf="image" (click)="removePic()" [disabled]="image === null || submitting" color="warning">
        <ion-icon name="trash" color="light"></ion-icon> Remove Image
      </ion-button>
    </ion-item>
    <p class="ion-text-center" *ngIf="image">
      <img slot="end" [src]="image.data" (click)="image != null && openImage()" class="preview" align="center">
    </p>
    <ion-item>
      <ion-label position="stacked">Note</ion-label>
      <ion-input name="note" type="text" placeholder="..." [(ngModel)]="note">
      </ion-input>
    </ion-item>
    <div class="ion-text-center">
      <app-progress-bar *ngIf="submitting" [progress]="progress"></app-progress-bar>
      <ion-button size="large" type="submit" [disabled]="submitting" expand="block">
        Submit Note
      </ion-button>
    </div>
  </form>
</ion-content>