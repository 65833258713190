import { Component, OnInit } from '@angular/core';
import { BackButtonService } from 'src/app/services/back-button.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css']
})
export class BackButtonComponent implements OnInit {


  constructor(private navCtrl: NavController, private backButtonService: BackButtonService) { }

  goBack() {
    this.navCtrl.navigateBack(this.backButtonService.getPreviousUrl());
  }

  ngOnInit() {
  }

}
