import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewService } from 'src/app/services/ngx-drupal7-services';
import { HelpersService, CreateDeliveryService, ROLL_STOCK_EDIT } from 'src/app/services/helpers.service';
import { CreateNotePage } from '../../pages/modal/create-note/create-note.page';
import { CreateChildRollPage } from '../../pages/modal/create-child-roll/create-child-roll.page';
import { ModalController, AlertController } from '@ionic/angular';
import { VocabularyService } from 'src/app/services/vocabulary.service';

@Component({
  selector: 'app-block-roll-stock',
  templateUrl: './block-roll-stock.component.html',
  styleUrls: ['./block-roll-stock.component.css']
})
export class BlockRollStockComponent implements OnInit {

  values: any = null;
  loading: Boolean = true;
  @Input() node: any;
  @Output() modified = new EventEmitter<boolean>();
  core_sizes = [];
  warehouses = [];
  material_types = [];
  bays = [];

  constructor(
    public helpers: HelpersService,
    public alertController: AlertController,
    public vocabularyService: VocabularyService,
  ) {

    this.vocabularyService.getCoreSizes().then(items => { this.core_sizes = items; }, err => { this.helpers.presentToast(err); });
    this.vocabularyService.getWarehouseLocations().then(items => { this.warehouses = items; }, err => { this.helpers.presentToast(err); });
    this.vocabularyService.getMaterialTypes().then(items => { this.material_types = items; }, err => { this.helpers.presentToast(err); });
    this.vocabularyService.getBayLocations().then(items => { this.bays = items; }, err => { this.helpers.presentToast(err); });
  }


  initialize(data = null) {
    this.values = {
      rs_width: this.helpers.getValue(this.node, 'rs_width'),
      rs_caliper: this.helpers.getValue(this.node, 'rs_caliper'),
      rs_diameter: this.helpers.getValue(this.node, 'rs_diameter'),
      weight: this.helpers.getValue(this.node, 'weight'),
      core_size: this.helpers.getTaxonomyValue(this.node, 'core_size'),
      footage_manual_: this.helpers.getValue(this.node, 'footage_manual_'),
      material_type: this.helpers.getTaxonomyValue(this.node, 'material_type'),
      material_type_other: this.helpers.getValue(this.node, 'material_type_other'),
      warehouse_location: this.helpers.getTaxonomyValue(this.node, 'warehouse_location'),
      bay_location: this.helpers.getTaxonomyValue(this.node, 'bay_location'),
      rs_notes: this.helpers.getValue(this.node, 'rs_notes'),
      itempo: this.helpers.getValue(this.node, "itempo"),
      board_mfg: this.helpers.getValue(this.node, "board_mfg"),
      customer2: this.helpers.getValue(this.node, "customer2"),
      initial_weight: this.helpers.getValue(this.node, "initial_weight"),
      inventory_id: this.helpers.getValue(this.node, "inventory_id"),
      media_notes: this.helpers.getValue(this.node, "media_notes"),
      pending: this.helpers.getValue(this.node, "pending"),
      production_type: this.helpers.getValue(this.node, "production_type"),
      project: this.helpers.getValue(this.node, "project"),
      rs_barcode: this.helpers.getValue(this.node, "rs_barcode"),
      rs_comments: this.helpers.getValue(this.node, "rs_comments"),
      rs_roll_num: this.helpers.getValue(this.node, "rs_roll_num"),
      rs_roll_num_other: this.helpers.getValue(this.node, "rs_roll_num_other"),
    };
    this.loading = false;
    //console.log("values", this.values);
  }

  ngOnInit() {
    if (this.node && this.node.nid) {
      // console.log("Node", this.node);
      this.initialize();
    } else {
      this.loading = false;
    }

  }

}
