import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'striphtml'
})
export class StripHtmlPipe implements PipeTransform {
  transform(_value: string, args: string[] = []): string {
    if (_value && _value.length > 0) {
      let ret = _value.replace(/<(?:.|\n)*?>/gm, '');
      ret = ret.replace('&amp;', '&');
      return ret;

    } else {
      return '';
    }
  }
}
