import {
  Component,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  ElementRef,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import QrScanner from "src/assets/qr-scanner.min"

@Component({
  selector: 'app-barcode-scan',
  templateUrl: './barcode-scan.page.html',
  styleUrls: ['./barcode-scan.page.scss'],
})
export class BarcodeScanPage implements AfterViewInit, OnDestroy {
  @ViewChild("VideoElement") VideoElement: ElementRef;
  scanner: QrScanner;
  cameraId: string = '';

  barcode: any = undefined;
  started = false;



  ngAfterViewInit() {
    setTimeout(() => {
      this.startHtmlQrCode();
    }, 1000)
  }
  async startHtmlQrCode() {
    console.log("this. VideoElement", this.VideoElement.nativeElement);
    let cameras = await QrScanner.listCameras();
    console.log("cameras", cameras);
    this.scanner = new QrScanner(
      this.VideoElement.nativeElement,
      result => {
        if (result && result.data) {
          this.barcode = result.data;
          this.close();
        }
      }, {
      preferredCamera: 'environment',
      onDecodeError: error => {
        //console.log("onDecodeError", error);
      },
      highlightScanRegion: true,
      highlightCodeOutline: true,
    });

    this.scanner.start().then(() => {
      this.started = true;
    }, err => {
      alert("Unable to start the scanner" + err);
    });
  }

  constructor(public modalCtrl: ModalController) {

  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad BarcodeScanPage');
  }


  close() {
    this.scanner && this.scanner.stop && this.scanner.stop();
    this.modalCtrl.dismiss({
      barcode: this.barcode
    });

  }
  cancel() {
    this.scanner && this.scanner.stop && this.scanner.stop();
    this.modalCtrl.dismiss({
      barcode: undefined
    });
  }

  ngOnDestroy() {

  }

}
