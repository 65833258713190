import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

export enum DeliveryTypes {
    laminating = 'Laminating Board',
    cp = 'CP Board',
    customer_service = 'Customer/Service Material',
}
export enum PlantTypes {
    plant_1 = 'Plant 1',
    plant_2 = 'Plant 2',
}
export enum Warehouses {
    warehouse_1 = '1',
    warehouse_2 = '2',
    warehouse_3 = '3',
    warehouse_4 = '4',
    warehouse_5 = '5',
    warehouse_6 = '6',
    warehouse_7 = '7',
}

export enum WidthOptions {
    width_24 = '24', // Actually 24 3/4ths but we can't do that because of how the field uses an Interger list so we hust set this as 24.  The UI has this conditional to rename it in delivery-roll-component.html to 24 3/4ths
    width_25 = '25', // Actually 25 1/2 but we can't do that because of how the field uses an Interger list so we hust set this as 25.  The UI has this conditional to rename it in delivery-roll-component.html to 25 1/2
    width_32 = '32', // Actually 23 5/8ths but we can't do that because of how the field uses an Interger list so we hust set this as 32.  The UI has this conditional to rename it in delivery-roll-component.html to 32 5/8ths
    width_33 = '33',
    width_41 = '41',
    width_45 = '45', // Actually 45 3/4ths but we can't do that because of how the field uses an Interger list so we hust set this as 32.  The UI has this conditional to rename it in delivery-roll-component.html to 45 3/4ths
}

export enum MaterialTypes {
    roll_stock = 'Roll Stock',
    core = 'Core',
    paper = 'Paper',
    backing = 'Backing',
}

export enum BayLocations {
    '1N' = '1N',
    '1S' = '1S',
    '2N' = '2N',
    '2S' = '2S',
    '3N' = '3N',
    '3S' = '3S',
    '4N' = '4N',
    '4S' = '4S',
    '5N' = '5N',
    '5S' = '5S',
    '6N' = '6N',
    '6S' = '6S',
    '7N' = '7N',
    '7S' = '7S',
    '8N' = '8N',
    '8S' = '8S',
    '9N' = '9N',
    '9S' = '9S',
}

export interface DeliveryRoll {
    width: string;
    weight: string;
    barcode: string;
    color?: string;
    use?: string;
    number?: string;
    position?: string;
    warehouse_location?: Warehouses;
    bay_location?: BayLocations;
}
export const DeliveryRollDefaults: DeliveryRoll = {
    barcode: null,
    color: null,
    number: null,
    warehouse_location: null,
    weight: null,
    width: null,
    bay_location: null,
};

export interface Delivery {
    delivery_type: DeliveryTypes;
    material_type: MaterialTypes;
    plant_location: PlantTypes;
    warehouse_location?: Warehouses;
    rolls?: [DeliveryRoll];
}

export const DeliveryDefaults: Delivery = {
    delivery_type: null,
    material_type: null,
    plant_location: null,
    warehouse_location: null,
    // rolls: [],
};

@Injectable({
    providedIn: 'root',
})
export class DeliveryService {
    private _delivery: BehaviorSubject<Delivery> = new BehaviorSubject({ ...DeliveryDefaults });
    public readonly delivery: Observable<Delivery> = this._delivery.asObservable();

    constructor(private storage: StorageService) {
        storage.get('delivery').then((val) => {
            if (val) {
                this._delivery.next(val);
            } else {
                this._delivery.next({ ...DeliveryDefaults });
            }
        });
    }

    save(delivery: Delivery) {
        return this.storage.set('delivery', delivery).then(() => {
            this._delivery.next(delivery);
        });
    }

    // This funciton determines if the roll is valid and able to submit for delivery.
    isRollValid(type, value) {
        if (!type || !value) {
            return false;
        }
        const fields = [];
        switch (type) {
            case 'core':
                fields.push('barcode');
                fields.push('weight');
                fields.push('width');
                fields.push('number');
                fields.push('color');
                // fields.push('bay_location'); //Made this optional
                break;
            case 'paper':
                fields.push('barcode');
                fields.push('weight');
                fields.push('width');
                fields.push('color');
                // fields.push('bay_location'); //Made this optional
                fields.push('position');
                break;
            case 'backing':
                fields.push('barcode');
                fields.push('weight');
                fields.push('width');
                fields.push('color');
                // fields.push('bay_location'); //Made this optional
                fields.push('position');
                break;
            case 'roll_stock':
                fields.push('barcode');
                fields.push('weight');
                fields.push('width');
                fields.push('number');
                // fields.push('bay_location'); //Made this optional
                break;
        }
        for (const i in fields) {
            const field = fields[i];
            if (value[field] == undefined || value[field] == null || value[field] == '') {
                return false;
            }
        }
        return true;
    }
}
