import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError, Observer } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { HelpersService } from '../services/helpers.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        // private authService: AuthService,
        private route: ActivatedRoute,
        public helpers: HelpersService,
        private router: Router

    ) { }

    intercept(dupReq: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const source: Observable<HttpEvent<any>> = Observable.create((observer: Observer<HttpEvent<any>>) => {
            next
                .handle(dupReq)
                .pipe(
                    tap(
                        (event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                observer.next(event);
                                observer.complete();

                            }
                        },
                        (err: any) => {
                            const error = err.error.message || err.statusText;
                            switch (err.status) {
                                case 401:
                                    console.log('401 ERROR', err, this.route.snapshot.url);
                                    if (this.route.snapshot.url.length != 0) { // We're not already at the login page
                                        console.log('We are NOT on login page so try logging out');
                                        /*this.authService.logout().then(()=>{
                                            location.reload(true);
                                        });*/
                                        this.helpers.logout();
                                    } else {
                                        console.log('We are ALREADY on login page so do something else');
                                        // console.log("ERROR", error);
                                        this.helpers.presentToast(error);
                                        /*this.helpers.presentAlert({
                                            header: "Potential Connection Problem",
                                            message: "Click Refresh to try connecting again.  Contact support if this continues.",
                                            textConfirm: "Refresh",
                                            //textCancel: "Ignore",
                                            fnConfirm: ()=>{
                                                //location.reload(true);
                                                this.router.navigate(['/']);
                                                location.reload(true);
                                            }
                                        });*/
                                    }
                                    break;
                                default:
                                case 406:
                                    // console.log("406",error);
                                    if (this.route.snapshot.url.length != 0 && err.statusText == 'Not Acceptable : User is not logged in.') {
                                        this.helpers.logout();
                                        location.reload();
                                    } else {
                                        this.helpers.presentToast(error);

                                    }

                                    break;

                            }
                            // if cordova not available let service worker do the job

                            throwError(error);
                            observer.error(err);
                            observer.complete();
                        }
                    )
                )
                .subscribe();
        });
        return source;
    }
}
