<ion-header>
  <ion-toolbar color="medium">
    <ion-buttons slot="start">
      <ion-button size="small" shape="round" (click)="cancel()" color="tertiary" color="dark">
        <ion-icon slot="start" name="close"></ion-icon> Close
      </ion-button>
    </ion-buttons>
    <ion-title>Scan Barcode<ion-icon size="small" slot="icon-only" name="barcode"></ion-icon>
    </ion-title>
  </ion-toolbar>
</ion-header>



<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col col-12 text-center>
        <div id="scanner-container">
          <div *ngIf="!started" class="ion-text-center">
            <div class="ion-text-center ion-padding">
              <ion-spinner></ion-spinner>
            </div>
          </div>
          <video #VideoElement id="VideoElement"></video>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>