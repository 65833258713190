import { Component, OnInit, Input, } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { HelpersService, FieldCollectionService } from 'src/app/services/helpers.service';
import { FileUploader } from 'ad-file-upload';
import { ImagePage } from './../image/image.page';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import * as Ahdin from 'ahdin';

const STORAGE_KEY = 'note_images';

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.page.html',
  styleUrls: ['./create-note.page.scss'],
})
export class CreateNotePage implements OnInit {
  @Input() node: any;
  public uploader: FileUploader = new FileUploader({});
  image: any = null;
  note: string;
  submitting = false;
  processing = false;
  progress = 50;
  constructor(
    private nav: NavController,
    private modalCtrl: ModalController,
    private platform: Platform,
    public helpers: HelpersService,
    private fieldCollectionService: FieldCollectionService,
  ) { }

  ngOnInit() {

  }
  removePic() {
    this.image = null;
    this.processing = false;
  }

  // If orientation is an issue refer to this example https://stackblitz.com/edit/ionic-select-image-example?embed=1&file=pages/home/home.html
  presentUploader(fileLoader) {
    fileLoader.click();
    let that = this;
    fileLoader.onchange = (event) => {
      that.processing = true;
      console.log('Event', event);
      let reader = new FileReader();
      reader.onload = (data: any) => {
        console.log('loaded');
        Ahdin
          .compress(
            data.target.result,
            {
              maxWidth: 1000,
              outputFormat: 'jpeg'
            }
          )
          .then((compressedBlob) => {
            let reader = new FileReader();
            reader.onload = (data: any) => {
              this.image = {
                data: data.target.result,
                name: event.target.files[0].name
              };
              this.processing = false;
            };
            reader.readAsDataURL(compressedBlob);
          });
      };
      reader.readAsDataURL(event.target.files[0]);
    };
  }


  submit(form) {
    if (!this.note && !this.image) {
      this.helpers.presentToast('You must provide either an image or detail for this Note');
    } else {
      this.progress = 0;
      this.submitting = true;

      console.log('About to submit');

      this.fieldCollectionService.create(
        {
          type: 'media_notes',
          nid: this.node.nid,
          fields: {
            field_image: {
              file: this.image,
            },
            field_notes: this.helpers.prepValue(this.note)
          }
        }
      ).pipe(
        tap(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                console.log('HttpEventType.UploadProgress', event);
                this.progress = Math.round(100 * event.loaded / event.total);
                break;
              case HttpEventType.DownloadProgress:
                console.log('HttpEventType.DownloadProgress', event);
                this.progress = -1;
                break;
              case HttpEventType.Response:
                console.log('HttpEventType.Response', event);
                this.submitting = false;
                this.progress = 0;
                this.modalCtrl.dismiss({ note: true });
                break;
            }
          })).subscribe(() => { }, err => {
            console.log('Error', err);
            this.helpers.presentToast(err);
            this.submitting = false;
            this.progress = 0;
          });
    }
  }

  async openImage() {
    const modal = await this.modalCtrl.create({
      component: ImagePage,
      componentProps: { value: this.image.data, caption: this.image.name }
    });
    return await modal.present();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}

