import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent implements OnInit {
  loading: Boolean = true;
  @Input() node: any;

  constructor() {
    // TODO : CREATED AND UPDATED
    // label_and_pallets_utilities
  }

  initialize() {
    //console.log("Label", this.node)
    this.loading = false;
  }

  ngOnInit() {
    if (this.node && this.node.nid) {
      this.initialize();
    } else {
      this.loading = false;
    }

  }

}
