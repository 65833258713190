import { Injectable } from '@angular/core';
import * as localforage from 'localforage'; // this works!!!

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {
    localforage.config({
      name: 'App Storage'
    });
  }

  get(key: string): Promise<any> {
    return localforage.getItem(key);
  }

  set(key: string, value: any): Promise<any> {

    return localforage.setItem(key, value);
  }

  remove(key: string): Promise<any> {
    return localforage.removeItem(key);
  }

  DELETE_ALL() {
    return localforage.clear();
  }

  listKeys() {
    return localforage.keys();
  }
}