
import { Component, HostListener, NgZone } from '@angular/core';

import { NavController, LoadingController } from '@ionic/angular';

import { AuthService } from './services/auth.service';
import { ScanDetectorService, ScanDetected } from './services/scan-detector.service';
import { HelpersService } from './services/helpers.service';
import { SearchService, SearchResult } from './services/search.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.scanDetectorService.onKeyUp(event);
  }

  constructor(
    public navCtrl: NavController,
    public loadingCtrl: LoadingController,
    private authService: AuthService,
    public helpers: HelpersService,
    public searchService: SearchService,
    public scanDetectorService: ScanDetectorService,
    public ngZone: NgZone,

  ) {
    this.initializeApp();
    this.scanDetectorService.scan.subscribe((scan) => {
      const code = scan.value.substr(scan.value.lastIndexOf('/') + 1);
      // alert(code);
      this.search(code);
    });
  }


  async search(code) {
    if (!code && code.length < 5) {
      this.helpers.presentToast(`Barcode scanned <strong>(${code})</strong> does not appear to be valid`);
      return;
    } else {
      this.helpers.presentToast(`Scanning for <strong>${code}</strong>`);
    }
    const loader = await this.loadingCtrl.create();
    loader.present();
    this.searchService.search(code).then(
      (result: SearchResult) => {
        console.log("Search Result", result);
        loader.dismiss();
        console.log(`Going to ${result.link}`);
        this.ngZone.run(() => {
          this.helpers.go(result.link);
        });
      },
      (error) => {
        loader.dismiss();
        console.log("Error", error);
        this.helpers.presentToast(error);

      });
  }


  handleScan(event: ScanDetected) {
    console.log('Scan Detected', event);
  }

  initializeApp() {



  }


  async logout() {
    const loader = await this.loadingCtrl.create();
    loader.present();
    this.authService.logout().then(
      () => { loader.dismiss(); },
      err => {
        console.log('Error logging out', err);
        loader.dismiss();
      });

  }
}
