import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie';


@Injectable()
export class GenericInterceptor implements HttpInterceptor {
    constructor(private cookies: CookieService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /*console.log("Cookies", this.cookies.getAll());

        var authReq = this.cookies.get('XSRF-TOKEN') ? request.clone({
            withCredentials: true,
            headers: request.headers.set('X-XSRF-TOKEN', this.cookies.get('XSRF-TOKEN'))
        }) : request.clone({ withCredentials: true });
        console.log("Auth Req Intercept", authReq);*/
        request = request.clone({
            withCredentials: true
        });

        return next.handle(request);

    }
}
