import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './guards';

const routes: Routes = [
  { 
    path: '', 
    loadChildren: async () => {
      const { LoginPageModule } = await import('./pages/login/login.module');
      return LoginPageModule;
    }
  },
  {
    path: 'main',
    loadChildren: async () => {
      const { MainPageModule } = await import('./pages/main/main.module');
      return MainPageModule;
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'delivery',
    loadChildren: async () => {
      const { DeliveryPageModule } = await import('./pages/delivery/delivery.module');
      return DeliveryPageModule;
    },
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
