import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { TaxonomyVocabularyService } from 'src/app/services/ngx-drupal7-services';
import { StorageService } from 'src/app/services/storage.service';
import * as moment from 'moment';

export const VOCABULARY_CORE_SIZE = 46; // "core_size";
export const VOCABULARY_WAREHOUSE = 50; // "warehouse_location";
export const VOCABULARY_BAY_LOCATION = 44; // "bay_location";
export const VOCABULARY_MATERIAL_TYPE = 47; // "material_type";
export const VOCABULARY_AUDIT_OPTIONS = 57; // "audit_options";
export const VOCABULARY_PAPER_COLORS = 2; // "paper_colors";
export const VOCABULARY_CORE_COLORS = 3; // "core_colors";
export const VOCABULARY_BACKING_COLORS = 8; // "backing_colors";
export const VOCABULARY_ROLL_COLORS = 60; // "roll_colors";
export const VOCABULARY_ROLL_USES = 59; // "roll_uses";

const STORAGE_KEY_PREFIX = 'vocabularies-';

@Injectable({
    providedIn: 'root',
})
export class VocabularyService {
    // TODO : Convert these individual vars to a single object
    core_sizes = [];
    warehouses = [];
    material_types = [];
    bays = [];
    audit_options = [];
    paper_colors = [];
    core_colors = [];
    backing_colors = [];
    roll_colors = [];
    roll_uses = [];

    constructor(
        private storage: StorageService,
        private taxonomyVocabularyService: TaxonomyVocabularyService,
    ) {
        storage.get(STORAGE_KEY_PREFIX + 'core_sizes').then((val) => {
            if (val) {
                this.core_sizes = val;
            }
        });
        storage.get(STORAGE_KEY_PREFIX + 'warehouses').then((val) => {
            if (val) {
                this.warehouses = val;
            }
        });
        storage.get(STORAGE_KEY_PREFIX + 'material_types').then((val) => {
            if (val) {
                this.material_types = val;
            }
        });
        storage.get(STORAGE_KEY_PREFIX + 'bays').then((val) => {
            if (val) {
                this.bays = val;
            }
        });
        storage.get(STORAGE_KEY_PREFIX + 'audit_options').then((val) => {
            if (val) {
                this.audit_options = val;
            }
        });
        storage.get(STORAGE_KEY_PREFIX + 'paper_colors').then((val) => {
            if (val) {
                this.paper_colors = val;
            }
        });
        storage.get(STORAGE_KEY_PREFIX + 'core_colors').then((val) => {
            if (val) {
                this.core_colors = val;
            }
        });
        storage.get(STORAGE_KEY_PREFIX + 'backing_colors').then((val) => {
            if (val) {
                this.backing_colors = val;
            }
        });

        storage.get(STORAGE_KEY_PREFIX + 'roll_colors').then((val) => {
            if (val) {
                this.roll_colors = val;
            }
        });
        storage.get(STORAGE_KEY_PREFIX + 'roll_uses').then((val) => {
            if (val) {
                this.roll_uses = val;
            }
        });
    }

    getVocabulary(vocabulary, vid): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.isFresh(vocabulary).then(
                (isFresh) => {
                    if (isFresh) {
                        resolve(this[vocabulary]);
                        // console.log("Provide local " + vocabulary, this[vocabulary]);
                    } else {
                        this.taxonomyVocabularyService
                            .getTaxonomyVocabularyTree(vid)
                            .toPromise()
                            .then(
                                (items) => {
                                    this[vocabulary] = items;
                                    this.storage.set(STORAGE_KEY_PREFIX + vocabulary, items);
                                    const now = new Date();
                                    this.storage.set(
                                        STORAGE_KEY_PREFIX + vocabulary + '-refreshed',
                                        now,
                                    );
                                    // console.log("Get fresh " + vocabulary, items);
                                    resolve(items);
                                },
                                (error) => {
                                    reject(error);
                                },
                            );
                    }
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    getCoreSizes(): Promise<any> {
        return this.getVocabulary('core_sizes', VOCABULARY_CORE_SIZE);
    }
    getWarehouseLocations(): Promise<any> {
        return this.getVocabulary('warehouses', VOCABULARY_WAREHOUSE);
    }
    getBayLocations(): Promise<any> {
        return this.getVocabulary('bays', VOCABULARY_BAY_LOCATION);
    }
    getMaterialTypes(): Promise<any> {
        return this.getVocabulary('material_types', VOCABULARY_MATERIAL_TYPE);
    }
    getAuditOptions(): Promise<any> {
        return this.getVocabulary('audit_options', VOCABULARY_AUDIT_OPTIONS);
    }
    getPaperColors(): Promise<any> {
        return this.getVocabulary('paper_colors', VOCABULARY_PAPER_COLORS);
    }
    getCoreColors(): Promise<any> {
        return this.getVocabulary('core_colors', VOCABULARY_CORE_COLORS);
    }
    getBackingColors(): Promise<any> {
        return this.getVocabulary('backing_colors', VOCABULARY_BACKING_COLORS);
    }
    getRollColors(): Promise<any> {
        return this.getVocabulary('roll_colors', VOCABULARY_ROLL_COLORS);
    }
    getRollUses(): Promise<any> {
        return this.getVocabulary('roll_uses', VOCABULARY_ROLL_USES);
    }

    isFresh(vocabulary): Promise<Boolean> {
        return new Promise<Boolean>((resolve, reject) => {
            this.storage.get(STORAGE_KEY_PREFIX + vocabulary + '-refreshed').then((val) => {
                if (val) {
                    const when = moment(val);
                    if (
                        val == null ||
                        when.add(environment.freshnessVocab, 'seconds').isBefore(moment())
                    ) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                } else {
                    resolve(false);
                }
            });
        });
    }
}
