import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TaxonomyTermService, ViewService, FileService, NodeService } from 'src/app/services/ngx-drupal7-services';
import { Note } from '../note/note.component';
import { Audit } from '../audit/audit.component';
import * as moment from 'moment';
import { HelpersService } from 'src/app/services/helpers.service';

@Component({
    selector: 'app-value-print',
    templateUrl: './value-print.component.html',
    styleUrls: ['./value-print.component.scss'],
})
export class ValuePrintComponent implements OnInit {
    @Input() inline = false;
    @Input() label = '';
    @Input() value: any;
    @Input() prefix = '';
    @Input() suffix = '';
    @Input() type;
    @Input() showIfEmpty = false;
    @Input() capitalize = false;
    @Input() preserveHTML = false;

    externalLink;
    initialized: Boolean = false;
    final: any = null;
    notes: Note[];
    audits: Audit[];
    raw_materials: any;

    titleCase(str) {
        const splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    numberWithCommas(x) {
        const parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

    stripHTML(str) {
        if (str) {
            return str.replace(/<(?:.|\n)*?>/gm, '');
        } else {
            return '';
        }
    }

    constructor(
        private sanitizer: DomSanitizer,
        private taxonomyTermService: TaxonomyTermService,
        private viewService: ViewService,
        private nodeService: NodeService,
        private fileService: FileService,
        private helpers: HelpersService,
    ) {}

    initialize() {
        //console.log("Value", this.value);

        switch (typeof this.value) {
            case 'boolean':
            case 'string':
            case 'number':
                this.final = this.value;
                break;
            default:
                if (this.value && this.value.und && this.value.und[0] && this.value.und.length == 1) {
                    if (this.value.und[0].value != undefined && this.value.und[0].value != null) {
                        this.final = this.value.und[0].value;
                    } else if (this.value.und[0].name != undefined && this.value.und[0].name != null) {
                        this.final = this.value.und[0].name;
                    } else if (this.value.und[0].fid != undefined && this.value.und[0].fid != null) {
                        this.final = 'Loading...';
                        this.fileService.getFileById(this.value.und[0].fid).subscribe(
                            (data) => {
                                this.final = data.filename;
                                this.externalLink = data.uri_full;
                            },
                            (err) => {
                                this.final = err;
                            },
                        );
                    } else if (this.value.und[0].tid != undefined && this.value.und[0].tid != null) {
                        this.final = 'Loading...';
                        this.taxonomyTermService.getTaxonomyTermById(this.value.und[0].tid).subscribe(
                            (term) => {
                                this.final = term.name;
                            },
                            (err) => {
                                this.final = err;
                            },
                        );
                    } else if (this.value.und[0].target_id != undefined && this.value.und[0].target_id != null) {
                        this.final = this.value.und[0].target_id;
                    }
                } else if (this.value && this.value.und && this.value.und[0]) {
                    if (this.value.und[0].target_id != undefined && this.value.und[0].target_id != null) {
                        this.final = this.value.und
                            .map((item) => {
                                return item.target_id;
                            })
                            .join(', ');
                    } else {
                        this.final = this.value;
                    }
                }
                break;
        }
        if (this.final != null) {
            switch (this.type) {
                case 'timestamp':
                    this.final = moment.unix(this.value).format('MM/DD/YY hh:mma');
                    break;
                case 'string':
                    break;
                case 'number':
                    this.final = this.numberWithCommas(this.final);
                    break;
                case 'boolean':
                    if (this.final == 0 || this.final == '0' || this.final == false) {
                        this.final = 'No';
                    } else {
                        this.final = 'Yes';
                    }
                    break;
                case 'raw_materials':
                    if (this.value != null && this.value.und) {
                        const promises = [];
                        this.value.und.forEach((item) => {
                            promises.push(
                                new Promise((resolve, reject) => {
                                    this.viewService
                                        .getView('converting_raw_materials_list', {
                                            display_id: 'mobile',
                                            args: item.value,
                                        })
                                        .subscribe(
                                            (data) => {
                                                if (data && data[0]) {
                                                    let final = data[0];
                                                    return this.nodeService
                                                        .getNodeById(final['roll stock'])
                                                        .toPromise()
                                                        .then(
                                                            (roll) => {
                                                                final.roll = roll;
                                                                resolve(final);
                                                            },
                                                            (err) => {
                                                                reject(err);
                                                            },
                                                        );
                                                }
                                            },
                                            (err) => {
                                                reject(err);
                                            },
                                        );
                                }),
                            );
                        });
                        Promise.all(promises).then(
                            (items: any) => {
                                this.final = '';
                                this.raw_materials = items;
                            },
                            (error) => {
                                console.log('Error rendering raw materials', error);
                            },
                        );
                        this.final = 'Loading...';
                    }
                    break;

                case 'notes':
                    if (this.value != null && this.value.und) {
                        const promises = [];
                        this.value.und.forEach((note) => {
                            promises.push(
                                new Promise((resolve, reject) => {
                                    this.viewService
                                        .getView('field_collection_item', {
                                            display_id: 'field_collection',
                                            args: note.value,
                                        })
                                        .subscribe(
                                            (data) => {
                                                if (data && data[0]) {
                                                    resolve(data[0]);
                                                }
                                            },
                                            (err) => {
                                                reject(err);
                                            },
                                        );
                                }),
                            );
                        });
                        Promise.all(promises).then(
                            (notes: Note[]) => {
                                this.final = '';
                                this.notes = notes;
                            },
                            (error) => {
                                console.log('Error rendering notes', error);
                            },
                        );
                        this.final = 'Loading...';
                    }
                    break;
                case 'audits':
                    if (this.value != null && this.value.und) {
                        const promises = [];
                        this.value.und.forEach((audit) => {
                            promises.push(
                                new Promise((resolve, reject) => {
                                    this.viewService
                                        .getView('field_collection_item', {
                                            display_id: 'field_collection',
                                            args: audit.value,
                                        })
                                        .subscribe(
                                            (data) => {
                                                if (data && data[0]) {
                                                    resolve(data[0]);
                                                }
                                            },
                                            (err) => {
                                                reject(err);
                                            },
                                        );
                                }),
                            );
                        });
                        Promise.all(promises).then(
                            (audits: Audit[]) => {
                                this.final = '';
                                this.audits = audits;
                            },
                            (error) => {
                                console.log('Error rendering audits', error);
                            },
                        );
                        this.final = 'Loading...';
                    }
                    break;
                default:
                    // console.log("value-print.ts : missing type " + this.type);
                    break;
            }
        }
        // console.log(this.label + " : " + this.type, this.value, this.showIfEmpty, this.final);
        if (!this.preserveHTML) {
            this.final = this.stripHTML(this.final);
        }
        if (this.capitalize) {
            this.label = this.titleCase(this.label);
        }
        if (this.label == 'label' || this.label == 'Label') {
            const url = '/main/label/' + this.final.substring(4);
            this.final = `<a href='${url}' title='${this.final}'>${this.final}</a>`;
        }
        if (this.final && this.final != '') {
            switch (this.label) {
                case 'Pallet(s)':
                case 'pallet(s)':
                    this.processLinks(this.final, '/main/dashboard/pallet/', 'PAL').then((final) => {
                        this.final = final;
                        this.initialized = true;
                    });
                    break;
                case 'Roll(s)':
                case 'Roll Stock(s)':
                case 'roll(s)':
                case 'Parent Roll Stock':
                case 'Master Roll Stock':
                case 'Created Roll Stock(s)':
                    //console.log('Roll Stock', this.value, this.final);
                    this.processLinks(this.final, '/main/dashboard/roll-stock/', 'RLS').then((final) => {
                        this.final = final;
                        this.initialized = true;
                    });
                    break;
                case 'Paper(s)':
                case 'paper(s)':
                case 'Parent Paper Roll':
                case 'Master Paper Roll':
                case 'Created Paper Roll(s)':
                    this.processLinks(this.final, '/main/dashboard/paper/', 'PAP').then((final) => {
                        this.final = final;
                        this.initialized = true;
                    });
                    break;
                case 'Core(s)':
                case 'core(s)':
                case 'Parent Core Roll':
                case 'Master Core Roll':
                case 'Created Core Roll(s)':
                    this.processLinks(this.final, '/main/dashboard/core/', 'COR').then((final) => {
                        this.final = final;
                        this.initialized = true;
                    });
                    break;
                case 'Backing(s)':
                case 'backing(s)':
                case 'Parent Backing Roll':
                case 'Master Backing Roll':
                case 'Created Backing Roll(s)':
                    this.processLinks(this.final, '/main/dashboard/backing/', 'BAK').then((final) => {
                        this.final = final;
                        this.initialized = true;
                    });
                    break;
                default:
                    this.final = this.sanitizer.bypassSecurityTrustHtml(this.final);
                    this.initialized = true;
                    break;
            }
        }
    }

    processLinks(final, base, prefix): Promise<string> {
        return new Promise<string>((resolve) => {
            if (final.replace(/^\D+/g, '').length > 0) {
                switch (this.type) {
                    case 'barcodes':
                        this.final = 'Loading...';
                        this.initialized = true; // force temporary show of values
                        const promises = [];
                        final.split(',').map((node) => {
                            promises.push(
                                new Promise((resolve_node) => {
                                    const id = node.replace(/^\D+/g, '');
                                    const url = base + id;

                                    this.nodeService.getNodeById(id).subscribe((node) => {
                                        if (node) {
                                            const barcode = this.helpers.getValue(node, 'rs_barcode');
                                            if (barcode) {
                                                resolve_node(`<a href='${url}' title='${barcode}'>${barcode}</a>`);
                                            } else {
                                                resolve_node(`<a href='${url}' title='${prefix}${id}'>${prefix}${id}</a>`);
                                            }
                                        } else {
                                            resolve_node(`<a href='${url}' title='${prefix}${id}'>${prefix}${id}</a>`);
                                        }
                                    });
                                }),
                            );
                        });
                        Promise.all(promises).then((results) => {
                            //console.log('All Nodes', results);
                            resolve(results.join(', '));
                        });
                        break;
                    default:
                        resolve(
                            final
                                .split(',')
                                .map((node) => {
                                    const id = node.replace(/^\D+/g, '');
                                    const url = base + id;
                                    return `<a href='${url}' title='${prefix}${id}'>${prefix}${id}</a>`;
                                })
                                .join(', '),
                        );
                        break;
                }
            } else {
                resolve(this.final);
            }
        });
    }
    ngOnInit() {
        this.initialize();
    }
    clicked() {
        if (this.externalLink) {
            alert('external link not implemented on pwa');
        }
    }

    clickedRaw(item) {
        this.helpers.presentOptions({
            buttons: [
                {
                    text: 'Go to Roll',
                    handler: () => {
                        this.helpers.go('/main/dashboard/roll-stock/' + item.roll.nid);
                    },
                },
                {
                    text: 'Edit Pull List',
                    handler: () => {
                        this.helpers.go('/main/raw-materials-pull-list/' + item['views_php_0']);
                    },
                },
            ],
        });
    }
}
