<ion-list class="ion-text-center" *ngIf="!loading && !pallet">
  <p>Unable to load pallet details</p>
</ion-list>
<ion-list *ngIf="pallet">
  <ion-item-divider>
    <ion-label>Pallet Details</ion-label>
  </ion-item-divider>
  <app-value-print *ngIf="this.state" label="Status" [value]="this.state.title"></app-value-print>
  <app-value-print label="ID" [value]="pallet.nid" prefix="PAL"></app-value-print>
  <app-value-print label="Created" [value]="pallet.created" type="timestamp"></app-value-print>
  <app-value-print label="Modified" [value]="pallet.changed" type="timestamp"></app-value-print>
  <app-value-print label="Submitted By" [value]="pallet.name"></app-value-print>
  <app-value-print label="Shipped" [value]="pallet.field_used" type="boolean"></app-value-print>
  <app-value-print label="Ready to Ship" [value]="pallet.field_ready_to_ship" type="boolean"></app-value-print>
  <app-value-print label="Customer" [value]="pallet.field_customer2"></app-value-print>
  <app-value-print label="PO" [value]="pallet.field_itempo"></app-value-print>
  <app-value-print label="# of Pieces/Pallet" [value]="pallet.field_number_of_sheets" type="number"></app-value-print>
  <app-value-print label="Weight" [value]="pallet.field_weight" type="number"></app-value-print>
  <app-value-print label="Location (Warehouse)" [value]="pallet.field_location_warehouse"></app-value-print>
  <app-value-print label="Location (Row)" [value]="pallet.field_location_row"></app-value-print>
  <app-value-print label="Size" [value]="pallet.field_size_caliper" type="number"></app-value-print>
  <app-value-print label="Caliper" [value]="pallet.field_caliper2" type="number"></app-value-print>
  <app-value-print label="Rows" [value]="pallet.field_rows" type="number"></app-value-print>
  <app-value-print label="Part #" [value]="pallet.field_part_no_"></app-value-print>
  <app-value-print label="Cartons" [value]="pallet.cartons" type="number"></app-value-print>
  <app-value-print label="Converting Type" [value]="pallet.field_converting_type"></app-value-print>
  <app-value-print label="Additional Info" [value]="pallet.field_additional_information"></app-value-print>
  <div class="ion-text-center">
    <ion-button expand="block" (click)="createNote()" size="large">Attach Note <ion-icon name="attach" slot="end">
      </ion-icon>
    </ion-button>
  </div>
  <app-value-print label="Notes" [value]="pallet.field_media_notes" type="notes"></app-value-print>
</ion-list>
<ion-list *ngIf="label">
  <!--<app-value-print *ngFor='let property of label | keys' [label]="property" [capitalize]="true"
    [value]="label[property]"></app-value-print>-->
  <app-label [node]="label"></app-label>
</ion-list>
<ion-list>
  <ion-button expand="block" (click)="createAudit()" size="large">Audit <ion-icon name="clipboard" slot="end">
    </ion-icon>
  </ion-button>
  <app-value-print label="Audits" [value]="pallet.field_audit" type="audits"></app-value-print>

</ion-list>

<p class="ion-text-center" *ngIf="loading">
  <ion-spinner></ion-spinner>
</p>