import { Injectable, EventEmitter, Output } from '@angular/core';

export interface ScannerConfiguration {
  minLength?: Number;
  maxLength?: number;
  scannerStartsWith?: string;
  scannerEndsWith?: string;
  scanTimeout?: number;
  replaceNotNumber?: boolean;
  allowNotNumber?: boolean;
  ignoreOverElement?: string[]; // ['INPUT'] - array of tag names that should disable emit
}

export interface ScanDetected {
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class ScanDetectorService {

  set config(input: ScannerConfiguration) {
    console.log('ScanDetectorService.config = ', input);
    this._config = Object.assign(this._config, input);
  }
  constructor() { }
  @Output() scan: EventEmitter<ScanDetected> = new EventEmitter();

  private _config: ScannerConfiguration = {
    minLength: 5,
    maxLength: 500,
    scannerStartsWith: '',
    scannerEndsWith: '',
    scanTimeout: 200,
    allowNotNumber: true,
    replaceNotNumber: false,
    ignoreOverElement: ['INPUT', 'TEXTAREA'],
  };
  private checkRegex: RegExp = new RegExp(`^${this._config.scannerStartsWith}${this._config.allowNotNumber ? '.' : '\\d'}{${this._config.minLength},${this._config.maxLength}}${this._config.scannerEndsWith}$`);

  private _input = '';

  scanTimer;

  private getCharacterFromEvent(e) {
    let code = e.which;

    // These are special cases that don't fit the ASCII mapping
    const exceptions = {
      32: ' '.charCodeAt(0),
      186: 59, // ;
      187: 61, // =
      188: 44, // ,
      189: 45, // -
      190: 46, // .
      191: 47, // /
      192: 96, // `
      219: 91, // [
      220: 92, // \
      221: 93, // ]
      222: 39, // '
      // numeric keypad
      96: '0'.charCodeAt(0),
      97: '1'.charCodeAt(0),
      98: '2'.charCodeAt(0),
      99: '3'.charCodeAt(0),
      100: '4'.charCodeAt(0),
      101: '5'.charCodeAt(0),
      102: '6'.charCodeAt(0),
      103: '7'.charCodeAt(0),
      104: '8'.charCodeAt(0),
      105: '9'.charCodeAt(0),
      106: '*'.charCodeAt(0),
      107: '+'.charCodeAt(0),
      109: '-'.charCodeAt(0),
      110: '.'.charCodeAt(0),
      111: '/'.charCodeAt(0)
    };


    // Filter out non-alphanumeric key codes unless they're one of the exceptions
    if (code < 48 || code > 90) {
      if (exceptions[code] !== undefined) {
        code = exceptions[code];
      } else {
        return null;
      }
    }

    let ch = String.fromCharCode(code);

    // If shifted translate characters, otherwise make lowercase
    if (e.shiftKey) {
      const special = {
        1: '!',
        2: '@',
        3: '#',
        4: '$',
        5: '%',
        6: '^',
        7: '&',
        8: '*',
        9: '(',
        0: ')',
        ',': '<',
        '.': '>',
        '/': '?',
        ';': ':',
        '\'': '"',
        '[': '{',
        ']': '}',
        '\\': '|',
        '`': '~',
        '-': '_',
        '=': '+'
      };

      if (special[ch] !== undefined) {
        ch = special[ch];
      }
    } else {
      ch = ch.toLowerCase();
    }
    return ch;
  }
  public onKeyUp(ev: any) {

    if (this._config.ignoreOverElement.includes(ev.target.tagName)) {
      return;
    }
    const character = this.getCharacterFromEvent(ev);
    if (character === null) {
      return;
    }
    this._input += character;

    if (this.scanTimer) {
      clearTimeout(this.scanTimer);
    }

    this.scanTimer = setTimeout(() => {
      if (this.checkRegex.test(this._input)) {
        const replace: RegExp =
          this._config.replaceNotNumber ?
            new RegExp(`\\D${this._config.scannerStartsWith.length ? '\|' + this._config.scannerStartsWith : ''}${this._config.scannerEndsWith.length ? '\|' + this._config.scannerEndsWith : ''}`, 'g') :
            new RegExp(`${this._config.scannerStartsWith}${this._config.scannerEndsWith.length ? '\|' + this._config.scannerEndsWith : ''}`, 'g');

        this.scan.emit({ value: this._input.replace(replace, '') });
      }
      this._input = '';
    }, this._config.scanTimeout);
  }
}
