import { Component, OnInit } from '@angular/core';
import { HelpersService } from 'src/app/services/helpers.service';
import { SearchService, SearchResult } from 'src/app/services/search.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  searchKey: any = '';
  loading = false;

  constructor(
    public helpers: HelpersService,
    public searchService: SearchService,
  ) { }

  async scan() {

    this.helpers.scanBarcode().then(_barcode => {
      const barcode = _barcode.substr(_barcode.lastIndexOf('/') + 1);
      if (barcode && barcode != '') {
        this.searchKey = decodeURIComponent(barcode);
        this.search();
      }
    }).catch(err => {
      this.helpers.presentToast(err);
      console.log(err);
    });
  }

  search() {
    if (!this.searchKey || this.searchKey.length < 5) {
      this.helpers.presentToast(`Barcode entered <strong>(${this.searchKey})</strong> does not appear to be valid`);
      return;
    }
    this.loading = true;

    this.searchService.search(this.searchKey).then(
      (result: SearchResult) => {
        this.helpers.go(result.link);
        this.loading = false;

      },
      (error) => {
        this.helpers.presentToast(error);
        this.loading = false;
      });
  }

  ngOnInit() {
  }

}
