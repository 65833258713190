import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlantSwitcherComponent } from './plant-switcher/plant-switcher.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { ValuePrintComponent } from './value-print/value-print.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { KeysPipe } from '../pipes/keys.pipe';
import { StripHtmlPipe } from '../pipes/strip-html.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { RouteTransformerDirective } from '../directives/route-transformer.directive';
import { PalletComponent } from './pallet/pallet.component';
import { LabelComponent } from './label/label.component';
import { DeliveryRollComponent } from './delivery-roll/delivery-roll.component';
import { NoteComponent } from './note/note.component';
import { AuditComponent } from './audit/audit.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RollStockComponent } from './roll-stock/roll-stock.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { PaperComponent } from './paper/paper.component';
import { CoreComponent } from './core/core.component';
import { BackingComponent } from './backing/backing.component';
import { BlockRollStockComponent } from './block-roll-stock/block-roll-stock.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    KeysPipe,
    StripHtmlPipe,
    TruncatePipe,
    PlantSwitcherComponent,
    SearchBarComponent,
    PalletComponent,
    ValuePrintComponent,
    BackButtonComponent,
    RouteTransformerDirective,
    LabelComponent,
    DeliveryRollComponent,
    NoteComponent,
    AuditComponent,
    ProgressBarComponent,
    RollStockComponent,
    DeliveryComponent,
    PaperComponent,
    CoreComponent,
    BackingComponent,
    BlockRollStockComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    MomentModule,
    CommonModule,
    RouterModule
  ],
  providers: [

  ],
  exports: [
    KeysPipe,
    StripHtmlPipe,
    TruncatePipe,
    PlantSwitcherComponent,
    SearchBarComponent,
    PalletComponent,
    ValuePrintComponent,
    BackButtonComponent,
    RouteTransformerDirective,
    LabelComponent,
    DeliveryRollComponent,
    NoteComponent,
    AuditComponent,
    ProgressBarComponent,
    RollStockComponent,
    DeliveryComponent,
    PaperComponent,
    CoreComponent,
    BackingComponent,
    BlockRollStockComponent
  ]
})
export class ComponentsModule {
}
