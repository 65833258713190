import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/app/services/ngx-drupal7-services';
import { NavController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import * as Q from 'q';
import { first } from 'rxjs/operators';
import { _ } from 'core-js';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public user: Observable<any> = this._user.asObservable();

  constructor(
    private userService: UserService,
    public navCtrl: NavController,
    private storage: StorageService
  ) {

  }
  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    this.storage.get('user').then((val) => {
      val ? this._user.next(val) : this._user.next(null);
    });
  }

  public get userValue(): any {
    return this._user.value;
  }

  public setUser(user) {
    return this.storage.set('user', user).then(() => {
      this._user.next(user);
    });
  }

  public login(user, pass): Promise<any> {

    return new Promise((resolve, reject) => {
      let finished = false;
      setTimeout(() => {
        if (!finished) {
          reject('Timeout has occurred');
        }
      }, environment.requestTimeout);
      this.userService.login({
        username: user,
        password: pass
      }).pipe(first()).subscribe(data => {
        finished = true;
        console.log('auth.service : login', data);
        if (data && data.user && data.user.uid != 0) {
          this.setUser(data.user).then(() => {
            resolve(data.user);
          });
        } else {
          reject(false);
        }
      }, err => {
        finished = true;
        reject(err);
      });
    });

  }

  public logout() {
    const deferred = Q.defer();


    this.userService.logout().pipe(first()).subscribe(data => {
      this._user.next(null);
      this.storage.remove('user').then(() => {
        this.navCtrl.navigateRoot('/');
        deferred.resolve();
      });
    }, err => {
      this.storage.remove('user').then(() => {
        this.navCtrl.navigateRoot('/');
        deferred.resolve();
      });
    });
    return deferred.promise;
  }


}
