import { Injectable } from '@angular/core';
import { ViewService } from 'src/app/services/ngx-drupal7-services';
import { AlertController } from '@ionic/angular';

export interface SearchResult {
  node?: Node;
  link: string;
}
export interface SearchLink {
  match: boolean;
  link?: string;
  type?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    public viewService: ViewService,
    public alertController: AlertController
  ) { }



  getLink(node): SearchLink {
    let match = true;
    let link: string;
    let type: string;
    switch (node.type) {
      case 'pallet':
        type = 'Pallet';
        link = '/main/dashboard/pallet/' + node.nid;
        break;
      case 'label_plant_2':
        type = 'Converting Label';
      case 'item':
        type = 'CP Label';
        link = '/main/dashboard/label/' + node.nid;
        break;
      case 'paper':
        type = 'Paper';
        link = '/main/dashboard/paper/' + node.nid;
        break;
      case 'core':
        type = 'Core';
        link = '/main/dashboard/core/' + node.nid;
        break;
      case 'backing':
        type = 'Backing';
        link = '/main/dashboard/backing/' + node.nid;
        break;
      case 'roll_stock':
        type = 'Roll Stock';
        link = '/main/dashboard/roll-stock/' + node.nid;
        break;
      case 'delivery':
        type = 'Delivery';
        link = '/main/deliveries/delivery/' + node.nid;
        break;
      default:
        match = false;
        break;
    }
    return {
      match,
      link,
      type
    };
  }

  search(query): Promise<any> {
    return new Promise((resolve, reject) => {
      let found = async (data) => {
        // console.log("Found", data);
        if (data.length == 1) {
          let node = data[0];
          let link = this.getLink(node);
          if (link.match) {
            resolve({
              node,
              link: link.link
            });
          } else {
            reject('A ' + node.type + ' in the system with ID ' + node.nid + ' was found, but the app data is not able to render it (yet).');
          }
        } else {
          let that = this;
          const alert = await this.alertController.create({
            backdropDismiss: false,
            header: 'Please choose',
            message: 'Multiple search results found',
            buttons: data.map(node => {
              let link = that.getLink(node);
              return {
                text: link.type + ' : ' + node.nid + '',
                handler: () => {
                  resolve({
                    node,
                    link: link.link
                  });
                }
              };
            }).concat([{
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                reject('Search canceled');
              }
            }])
          });
          await alert.present();
        }
      };
      query = query.replace(/^\s+|\s+$/g, '');
      const raw_query: any = 'RAW' + query;
      const raw_query2: any = 'RAW0' + query;

      this.viewService.getView('mobile_roll_details_json', { display_id: 'search_by_barcode', args: query }).subscribe(data => {
        if (data.length == 0) {
          console.log(`barcode query ${query} no results`)
          this.viewService.getView('mobile_roll_details_json', { display_id: 'search_by_barcode', args: raw_query }).subscribe(data => {
            if (data.length == 0) {
              console.log(`barcode rawquery ${raw_query} no results`)
              this.viewService.getView('mobile_roll_details_json', { display_id: 'search_by_barcode', args: raw_query2 }).subscribe(data => {
                if (data.length == 0) {
                  console.log(`barcode rawquery2 ${raw_query2} no results`)
                  this.viewService.getView('mobile_roll_details_json', { display_id: 'search_by_nid', args: query }).subscribe(data => {
                    if (data.length == 0) {
                      console.log(`bid query ${query} no results`)
                      this.viewService.getView('mobile_roll_details_json', { display_id: 'search_by_title', args: query }).subscribe(data => {
                        if (data.length == 0) {
                          console.log(`title query ${query} no results`)
                          this.viewService.getView('mobile_roll_details_json', { display_id: 'search_by_po', args: query }).subscribe(data => {
                            if (data.length == 0) {
                              console.log(`po query ${query} no results`)
                              this.viewService.getView('mobile_roll_details_json', { display_id: 'search_by_inventory_id', args: query }).subscribe(data => {
                                if (data.length == 0) {
                                  console.log(`inventory id query ${query} no results`)
                                  this.viewService.getView('mobile_roll_details_json', { display_id: 'search_by_incoming_roll_number', args: query }).subscribe(data => {
                                    if (data.length == 0) {
                                      console.log(`incoming roll # ${query} no results`)
                                      reject('No Results found for ' + query);
                                    } else {
                                      found(data);
                                    }
                                  }, (err) => {
                                    reject(err);
                                  });
                                } else {
                                  found(data);
                                }
                              }, (err) => {
                                reject(err);
                              });
                            } else {
                              found(data);
                            }
                          }, (err) => {
                            reject(err);
                          });

                        } else {
                          found(data);
                        }
                      }, (err) => {
                        reject(err);
                      });
                    } else {
                      found(data);
                    }
                  }, (err) => {
                    reject(err);
                  });
                } else {
                  found(data);
                }
              }, (err) => {
                reject(err);
              });
            } else {
              found(data);
            }
          }, (err) => {
            reject(err);
          });
        } else {
          found(data);
        }
      }, (err) => {
        reject(err);
      });
    });
  }


}
