import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewService } from 'src/app/services/ngx-drupal7-services';
import { HelpersService } from 'src/app/services/helpers.service';

import { CreateNotePage } from './../../pages/modal/create-note/create-note.page';
import { ModalController } from '@ionic/angular';
import { CreateChildRollPage } from 'src/app/pages/modal/create-child-roll/create-child-roll.page';
import { ConsumeRollPage } from 'src/app/pages/modal/consume-roll/consume-roll.page';

@Component({
    selector: 'app-paper',
    templateUrl: './paper.component.html',
    styleUrls: ['./paper.component.css'],
})
export class PaperComponent implements OnInit {
    loading: Boolean = true;
    @Input() node: any;
    @Output() modified = new EventEmitter<boolean>();

    constructor(private viewService: ViewService, public helpers: HelpersService, private modalCtrl: ModalController) {}

    initialize() {
        this.loading = false;
    }

    async createNote() {
        const modal = await this.modalCtrl.create({
            component: CreateNotePage,
            componentProps: { node: this.node },
        });
        modal.onDidDismiss().then((result: any) => {
            console.log('Create Note Dismissed', result);
            if (result.data && result.data.note) {
                this.modified.emit(true);
            }
        });
        return await modal.present();
    }
    createAudit() {
        this.helpers.createAudit(this.node).then(
            (result) => {
                console.log('Create Audit REsult', result);
                if (result) {
                    // this.modified.emit(true);
                    this.helpers.goBack();
                }
            },
            (err) => {
                this.helpers.presentToast(err);
            },
        );
    }
    async createChildRoll() {
        const modal = await this.modalCtrl.create({
            component: CreateChildRollPage,
            componentProps: { node: this.node },
        });
        modal.onDidDismiss().then((result: any) => {
            if (result.data && result.data.child) {
                this.modified.emit(true);
                this.helpers.go('/main/dashboard/paper/' + result.data.child.nid);
            }
        });
        return await modal.present();
    }
    async consumeRoll() {
        const modal = await this.modalCtrl.create({
            component: ConsumeRollPage,
            componentProps: { node: this.node },
        });
        modal.onDidDismiss().then((result: any) => {
            if (result.data) {
                this.modified.emit(true);
                //this.helpers.go('/main/paper/' + result.data.child.nid);
            }
        });
        return await modal.present();
    }
    ngOnInit() {
        if (this.node && this.node.nid) {
            console.log('Node', this.node);
            this.initialize();
        } else {
            this.loading = false;
        }
    }
}
