<div>
  <ion-app>
    <!--<ion-menu [swipeGesture]="false">
    <ion-content class="bg-profile">
      <ion-list>
        <ion-list-header color="primary">

        </ion-list-header>

        <ion-menu-toggle auto-hide="false">
          <ion-item [routerLink]="'/main/dashboard'" routerDirection="forward" color="primary">
            <ion-icon slot="start" name="home" color="light"></ion-icon>
            <ion-label>
              Dashboard
            </ion-label>
          </ion-item>
          <ion-item [routerLink]="'/main/production-list'" routerDirection="forward" color="primary">
            <ion-icon slot="start" name="pricetag" color="light"></ion-icon>
            <ion-label>
              Production List
            </ion-label>
          </ion-item>
          <ion-item [routerLink]="'/main/ready-to-ship'" routerDirection="forward" color="primary">
            <ion-icon slot="start" name="clipboard" color="light"></ion-icon>
            <ion-label>
              Ready to Ship
            </ion-label>
          </ion-item>
          <ion-item [routerLink]="'/main/shipped'" routerDirection="forward" color="primary">
            <ion-icon slot="start" name="bus" color="light"></ion-icon>
            <ion-label>
              Shipped
            </ion-label>
          </ion-item>
          <ion-item [routerLink]="'/main/deliveries'" routerDirection="forward" color="primary">
            <ion-icon slot="start" name="cube" color="light"></ion-icon>
            <ion-label>
              Deliveries
            </ion-label>
          </ion-item>
          <ion-item [routerLink]="'/main/delivery'" routerDirection="forward" color="primary">
            <ion-icon slot="start" name="cube" color="light"></ion-icon>
            <ion-label>
              Log Delivery
            </ion-label>
          </ion-item>
          <ion-item tappable (click)="logout()" routerDirection="backward" color="primary">
            <ion-icon slot="start" name="log-out" color="light"></ion-icon>
            <ion-label>
              Logout
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  -->
    <ion-router-outlet main [swipeGesture]="false"></ion-router-outlet>
  </ion-app>
</div>