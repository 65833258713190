<div [ngClass]="{'odd' : number % 2}" class="container">
  <div *ngIf="value" class="inner">
    <ion-toolbar color="light">
      <ion-title (click)="expanded = !expanded">
        <small>{{value.barcode ? value.barcode : "Roll #" + (number + 1) }}</small></ion-title>
      <ion-buttons slot="start">
        <ion-button *ngIf="expanded" size="small" (click)="expanded = !expanded">
          <ion-icon size="small" slot="icon-only" name="arrow-dropdown"></ion-icon>
        </ion-button>
        <ion-button *ngIf="!expanded" size="small" (click)="expanded = !expanded">
          <ion-icon size="small" slot="icon-only" name="arrow-dropright"></ion-icon>
        </ion-button>
        <ion-button size="small" (click)="expanded = !expanded">
          <ion-icon *ngIf="valid" size="small" slot="icon-only" name="checkmark" color="success"></ion-icon>
          <ion-icon *ngIf="!valid" size="small" slot="icon-only" name="alert" color="danger"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button size="small" (click)="actionRemove()">
          <ion-icon size="small" slot="icon-only" name="trash"></ion-icon>
        </ion-button>
        <ion-button size="small" (click)="actionClone()">
          <ion-icon size="small" slot="icon-only" name="copy"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <div *ngIf="material_type ==null">
      <ion-item full>
        <p class="ion-text-center">
          Please specify Material Type
        </p>
      </ion-item>
    </div>
    <div *ngIf="material_type !=null && expanded">
      <ion-item full>
        <ion-label position="stacked">Barcode<sup>*</sup></ion-label>
        <ion-input type="text" placeholder="RAW #" [(ngModel)]="value.barcode" required
          (ionChange)="uppercaseBarcode();valueChanged();">
        </ion-input>
        <ion-buttons slot="end">
          <ion-button size="large" shape="block" color="primary" (click)="scan()">
            <ion-icon name="barcode" size="large"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
      <ion-item *ngIf="['paper','core','backing'].includes(material_type)">
        <ion-label position="stacked">Width (inches)<sup>*</sup></ion-label>
        <ion-select name="width" interface="popover" [(ngModel)]="value.width" required (ionChange)="valueChanged()">
          <ion-select-option *ngFor="let width of widthOptions | keys" [value]="widthOptions[width]"
            class="ion-text-wrap">
            {{widthToDisplay(widthOptions[width])}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="['roll_stock'].includes(material_type)">
        <ion-label position="stacked">Width (inches)<sup>*</sup></ion-label>
        <ion-input name="width" type="number" [(ngModel)]="value.width" required (ionChange)="valueChanged()">
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Weight<sup>*</sup></ion-label>
        <ion-input name="weight" type="number" [(ngModel)]="value.weight" required (ionChange)="valueChanged()">
        </ion-input>
      </ion-item>
      <ion-item *ngIf="['roll_stock','core'].includes(material_type)">
        <ion-label position="stacked">Roll Number<sup>*</sup></ion-label>
        <ion-input name="number" type="text" [(ngModel)]="value.number" required (ionChange)="valueChanged()">
        </ion-input>
      </ion-item>
      <ion-item *ngIf="['roll_stock','paper','core','backing'].includes(material_type)">
        <ion-label position="stacked">Color<sup *ngIf="['paper','core','backing'].includes(material_type)">*</sup>
        </ion-label>
        <ion-select *ngIf="['roll_stock'].includes(material_type)" name="color" interface="popover"
          [(ngModel)]="value.color" required (ionChange)="valueChanged()">
          <ion-select-option *ngFor="let color of rollColors | keys" [value]="rollColors[color]" class="ion-text-wrap">
            {{rollColors[color]}}
          </ion-select-option>
        </ion-select>
        <ion-select *ngIf="['paper'].includes(material_type)" name="color" interface="popover" [(ngModel)]="value.color"
          required (ionChange)="valueChanged()">
          <ion-select-option *ngFor="let color of paperColors | keys" [value]="paperColors[color]"
            class="ion-text-wrap">
            {{paperColors[color]}}
          </ion-select-option>
        </ion-select>
        <ion-select *ngIf="['core'].includes(material_type)" name="color" interface="popover" [(ngModel)]="value.color"
          required (ionChange)="valueChanged()">
          <ion-select-option *ngFor="let color of coreColors | keys" [value]="coreColors[color]" class="ion-text-wrap">
            {{coreColors[color]}}
          </ion-select-option>
        </ion-select>
        <ion-select *ngIf="['backing'].includes(material_type)" name="color" interface="popover"
          [(ngModel)]="value.color" required (ionChange)="valueChanged()">
          <ion-select-option *ngFor="let color of backingColors | keys" [value]="backingColors[color]"
            class="ion-text-wrap">
            {{backingColors[color]}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="['paper','backing'].includes(material_type)">
        <ion-label position="stacked">Roll # position<sup>*</sup></ion-label>
        <ion-input name="position" type="text" [(ngModel)]="value.position" required (ionChange)="valueChanged()">
        </ion-input>
      </ion-item>
      <ion-item *ngIf="['roll_stock','paper','core','backing'].includes(material_type)">
        <ion-label position="stacked">Bay Location</ion-label>
        <ion-select name="width" interface="popover" [(ngModel)]="value.bay_location" required
          (ionChange)="valueChanged()">
          <ion-select-option *ngFor="let bay of bayLocations | keys" [value]="bayLocations[bay]" class="ion-text-wrap">
            {{bayLocations[bay]}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="['roll_stock'].includes(material_type)">
        <ion-label position="stacked">Use</ion-label>
        <ion-select name="use" interface="popover" [(ngModel)]="value.use" required (ionChange)="valueChanged()">
          <ion-select-option *ngFor="let use of rollUses | keys" [value]="rollUses[use]" class="ion-text-wrap">
            {{rollUses[use]}}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
  </div>
</div>