<ion-list class="ion-text-center" *ngIf="!loading && !node">
  <p>Unable to load label details</p>
</ion-list>
<ion-list *ngIf="node">
  <ion-item-divider>
    <ion-label>Label Details</ion-label>
  </ion-item-divider>
  <app-value-print label="ID" [value]="node.nid"></app-value-print>
  <app-value-print label="Type" [value]="node.field_converting_type"></app-value-print>
  <app-value-print label="Paper Color" [value]="node.field_color"></app-value-print>
  <app-value-print label="Core Color" [value]="node.field_core_color2"></app-value-print>
  <app-value-print label="Backing Color" [value]="node.field_backing_color"></app-value-print>
  <app-value-print label="Inventory?" [value]="node.field_inv" type="boolean"></app-value-print>
  <app-value-print label="Customer" [value]="node.field_customer2"></app-value-print>
  <app-value-print label="PO" [value]="node.field_itempo"></app-value-print>
  <app-value-print label="Size" [value]="node.field_size_caliper"></app-value-print>
  <app-value-print label="Caliper" [value]="node.field_caliper2"></app-value-print>
  <app-value-print label="Part No." [value]="node.field_part_no_"></app-value-print>
  <app-value-print label="Additional." [value]="node.field_additional_information"></app-value-print>
  <app-value-print label="Caliper" [value]="node.field_caliper"></app-value-print>
  <app-value-print label="Column" [value]="node.field_column"></app-value-print>
  <app-value-print label="Corner Color" [value]="node.field_cornercolor"></app-value-print>
  <app-value-print label="Leg Size" [value]="node.field_leg_size"></app-value-print>
  <app-value-print label="Length" [value]="node.field_length"></app-value-print>
  <app-value-print label="Length Fraction" [value]="node.field_lengthfraction"></app-value-print>
  <app-value-print label="Part No." [value]="node.field_part_no_"></app-value-print>
  <app-value-print label="Pcs/Row" [value]="node.field_pcs_row"></app-value-print>
  <app-value-print label="Pallets" [value]="node.field_plt"></app-value-print>
  <app-value-print label="Print" [value]="node.field_print" type="boolean"></app-value-print>
  <app-value-print label="Print Text" [value]="node.field_printtext"></app-value-print>
  <app-value-print label="Pallet(s)" [value]="node.field_itempallets"></app-value-print>
  <app-value-print type="raw_materials" label="Raw Material(s)" [value]="node.field_raw_materials"></app-value-print>



</ion-list>
<p class="ion-text-center" *ngIf="loading">
  <ion-spinner></ion-spinner>
</p>