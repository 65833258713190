<ion-list class="ion-text-center" *ngIf="!loading && !node">
  <p>Unable to load delivery details</p>
</ion-list>
<ion-list *ngIf="node">
  <ion-item-divider>
    <ion-label>Delivery Details <span *ngIf="pending">
        <ion-icon name="alert" size="small"></ion-icon><small>PENDING</small>
      </span></ion-label>
  </ion-item-divider>
  <app-value-print label="Delivery ID" [value]="node.nid"></app-value-print>
  <app-value-print label="Created" [value]="node.created" type="timestamp"></app-value-print>
  <app-value-print label="Modified" [value]="node.changed" type="timestamp"></app-value-print>
  <app-value-print label="Submitted By" [value]="node.name"></app-value-print>
  <app-value-print label="Date" [value]="node.field_date"></app-value-print>
  <app-value-print label="Delivery Type" [value]="node.field_roll_delivery_type"></app-value-print>
  <app-value-print label="Manufacturer" [value]="node.field_board_manu"></app-value-print>
  <app-value-print label="Customer" [value]="node.field_customer2"></app-value-print>
  <app-value-print label="Type" [value]="node.field_delivery_material_type"></app-value-print>
  <app-value-print label="Notes" [value]="node.field_notes"></app-value-print>
  <app-value-print label="Plant" [value]="node.field_plant_location"></app-value-print>
  <app-value-print label="Receiver Paperwork" [value]="node.field_receiver_paperwork"></app-value-print>
  <app-value-print label="Roll Stock(s)" [value]="node.field_roll_stock_item_multiple" type="barcodes"></app-value-print>
  <app-value-print label="Core(s)" [value]="node.field_core_multiple" type="barcodes"></app-value-print>
  <app-value-print label="Paper(s)" [value]="node.field_paper_multiple" type="barcodes"></app-value-print>
  <app-value-print label="Backing(s)" [value]="node.field_backing_multiple" type="barcodes"></app-value-print>
  <div class="ion-text-center">
    <ion-button expand="block" (click)="createNote()" size="large">Attach Note <ion-icon name="attach" slot="end">
      </ion-icon>
    </ion-button>
  </div>
  <app-value-print label="Notes" [value]="node.field_media_notes" type="notes"></app-value-print>

</ion-list>

<p class="ion-text-center" *ngIf="loading">
  <ion-spinner></ion-spinner>
</p>