import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

export const CP = 'cp';
export const CONVERTING = 'converting';

@Injectable({ providedIn: 'root' })
export class PlantService {
  private _plant: BehaviorSubject<string> = new BehaviorSubject(CP);
  public readonly plant: Observable<String> = this._plant.asObservable();

  constructor(private storage: StorageService) {
    storage.get('plant').then((val) => {
      if (val) {
        this._plant.next(val);
      } else {
        this._plant.next(CP);
      }
    });
  }

  set(plant) {
    this._plant.next(plant);
    this.storage.set('plant', plant);
  }

}
