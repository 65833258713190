import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {
    Drupal7ServicesModule,
    DrupalConstants,
    Settings,
} from 'src/app/services/ngx-drupal7-services';
import { ErrorInterceptor, GenericInterceptor } from './helpers';
import { environment } from '../environments/environment';
import { CookieModule } from 'ngx-cookie';
import { HelpersService } from './services/helpers.service';

// Modal Pages
import { ImagePageModule } from './pages/modal/image/image.module';
import { CreateNotePageModule } from './pages/modal/create-note/create-note.module';
import { CreateChildRollPageModule } from './pages/modal/create-child-roll/create-child-roll.module';
import { ConsumeRollPageModule } from './pages/modal/consume-roll/consume-roll.module';
import { BarcodeScanPageModule } from './pages/modal/barcode-scan/barcode-scan.module';
import { of } from 'rxjs';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            mode: 'ios',
        }),
        AppRoutingModule,
        HttpClientModule,
        Drupal7ServicesModule,
        CookieModule.forRoot(),
        ImagePageModule,
        CreateNotePageModule,
        CreateChildRollPageModule,
        ConsumeRollPageModule,
        BarcodeScanPageModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: GenericInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
    ],
    exports: [Drupal7ServicesModule],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(public helpers: HelpersService) {
        const drupalSettings: Settings = {
            apiProtocol: 'https',
            apiHost: environment.apiHost,
            apiEndPoint: 'api',
            language: 'und',
            requestTimeout: environment.requestTimeout,
            allowOffline: false,
        };
        DrupalConstants.Settings = drupalSettings;
        DrupalConstants.Instance.handleOffline = (err) => {
            console.log('DrupalConstants.Instance.handleOffline(err)', err);
            if (err.name && err.name == 'TimeoutError') {
                helpers.presentAlertConfirm({
                    fnConfirm: () => {
                        if (location.href == '/') {
                            location.reload();
                        } else {
                            location.href = '/';
                        }
                    },
                    header: 'A network timeout has occurred',
                    message:
                        'This can occur when the IMS is not sending a response fast enough to the app.  This generally occurs when the network is not quick enough to provide reliable data.  To prevent any inconsistencies we will attempt a restart of the app now.  If the app does not restart properly or this continues please close and reopen the app manually.',
                    textConfirm: 'Restart',
                });
            }

            return of();
        };
    }
}
