import { Component, OnInit, Input } from '@angular/core';
import { ImagePage } from './../../pages/modal/image/image.page';
import { ModalController } from '@ionic/angular';

export interface Note {
  id: string;
  thumbnail: string;
  note: string;
  image: string;
  when: string;
}


@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit {
  @Input() note: Note;

  constructor(
    public modalCtrl: ModalController,
  ) { }

  initialize() {
    // console.log("Note", this.note);
  }

  async openImage() {
     const modal = await this.modalCtrl.create({
        component: ImagePage,
        componentProps: { value: this.note.image, caption: this.note.note }
      });
     return await modal.present();
  }

  ngOnInit() {
    // console.log("this.pallet", this.pallet);
    if (this.note) {
      this.initialize();
    }

  }

}
